import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var viewportContext = React.createContext({});
// type AllProps={
//   children:React.ReactNode
// }
export var ViewportProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(window.innerWidth), width = _b[0], setWidth = _b[1];
    var _c = React.useState(window.innerHeight), height = _c[0], setHeight = _c[1];
    var handleWindowResize = function () {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    React.useEffect(function () {
        window.addEventListener("resize", handleWindowResize);
        return function () { return window.removeEventListener("resize", handleWindowResize); };
    }, []);
    return (_jsx(viewportContext.Provider, { value: { width: width, height: height }, children: children }));
};
export var useViewport = function () {
    var _a = React.useContext(viewportContext), width = _a.width, height = _a.height;
    return { width: width, height: height };
};
