import { jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { flagFeature } from "../common.actions";
var InitialValue = {
    isInitializing: false,
    FlagFeaturesData: {},
};
export var FeatureFlag = createContext(InitialValue);
export var FeatureFlagProvider = function (_a) {
    var children = _a.children;
    var _b = useState(InitialValue), featureFlag = _b[0], setFeatureFlag = _b[1];
    var _c = useState(false), loadChild = _c[0], setLoadChild = _c[1];
    var dispatch = useDispatch();
    var updateFlag = function (data) {
        setFeatureFlag(data);
    };
    useEffect(function () {
        flagFeature(function (response) {
            setLoadChild(true);
            updateFlag({ isInitializing: response.data.Status, FlagFeaturesData: response.data.Result });
        }, dispatch);
    }, [true]);
    return _jsxs(FeatureFlag.Provider, { value: featureFlag, children: [loadChild && children, " "] });
};
