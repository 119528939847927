var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
/* **********************************  Keys Defination *********************************
ModuleName                  : Display Module Name
ModuleKeys                  : Module Key for Identification for Module
iconClass                   : Display Module Icon
hasNavChild                 : Check Sub Page View Display
routeUrl                    : Navigation Url/Route
isShow                      : Toggle View handle Collapse/Expand
permissionType              : Identification of Permission
componentElementOnPage      : Element's Display on Parent Pages
isVisibleOnNavigation       : Display In Navigation Component Parent/Child
title                       : Display SubChild Tile Name
breadcrumbsTitle            : Display in BreadCrumb Name
breadcrumbsParentPath       : Display BreadCrumb Path
breadcrumbsParentTitle      : Display BreadCrumb Parent Title
oslUrl                      : For Validate OSL path if allow permission
oslMethod                   : For Validate OSL Method if allow permission
searchParams                : For Global Search
uniqueKey                   : Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]
***************************************************************************************/
export var MODULES;
(function (MODULES) {
    MODULES[MODULES["Home"] = 0] = "Home";
    MODULES[MODULES["Membership_Management"] = 1] = "Membership_Management";
    MODULES[MODULES["Committee_Management"] = 2] = "Committee_Management";
    MODULES[MODULES["Renewal_Tasks"] = 3] = "Renewal_Tasks";
    MODULES[MODULES["Work_Item"] = 4] = "Work_Item";
    MODULES[MODULES["Ballot_Admin"] = 5] = "Ballot_Admin";
    MODULES[MODULES["Admin"] = 6] = "Admin";
    MODULES[MODULES["Reports"] = 7] = "Reports";
    MODULES[MODULES["All_Documents"] = 8] = "All_Documents";
})(MODULES || (MODULES = {}));
export var LEFT_NAVIGATION_PAGE_URL = {
    DASHBOARD: "/dashboard",
    ALL_DOCUMENTS: "/documents-all",
    AGENDAS: "/documents-agenda",
    MINUTES: "/documents-minutes",
    DOCUMENT_UPLOAD: "/docupload",
    COMMITTES: "/documents-committees",
    VOTE_ATTACHMENTS: "/documents-voteAttachment",
    BALLOT_QUEUE: "/documents-ballotQueue",
    CLOSING_REPORTS: "/documents-closingReports",
    BALLOT: "/ballot",
    BALLOT_ITEMS: "/documents-ballotItems",
    LITIGATION_HOLD: "/litigation-hold",
    COLLABORATION_AREA_DRAFT: "/documents-collaborationAreaDraft"
};
export var PAGE_CONFIG = {
    // Privilege Type
    USER_PRIVILEGE: {
        ALL: "All",
        VIEW: "View",
        ADD: "Add",
        UPDATE: "Update",
        DELETE: "Delete",
        EXPORT: "Export",
        SEQUENCE: 'Sequence',
        PUBLISH: "Publish"
    },
    // HTTP METHODS
    HTTP_METHOD: {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE",
    },
    PAGE_TYPE: {
        DETAIL: function (page) { return "".concat(page, "-Detail"); },
        HOME: "Home",
        MEMBER: "Member",
        ORGANIZATION: "Organization",
        COMMITTEE: "Committee",
        TASK: "Task",
        USER: "User",
        ROLE: "Role",
        FEEGROUP: "Feegroup",
        WORK_ITEM_ADMIN_TOOL: "WORK_ITEM_ADMIN_TOOL",
        BALLOT_ADMIN: "BALLOT_ADMIN",
        MEMBERSHIP_RENEWAL: "MembershipRenewal",
        ADD_MEMBER: "Addmember",
        MEMBER_EXCEPTION: "MEMBER_EXCEPTION",
        BOS_VOLUME_LOCATION: "BOS_VOLUME_LOCATION",
        MEMBERSHIP_REPORT: "MEMBERSHIP_REPORT",
        RENEWAL_REPORT: "RENEWAL_REPORT",
        MEETING_REPORT: "MEETING_REPORT",
        DASHBOARD: "DASHBOARD",
        DOCUMENT_UPLOAD: "DOC_UPLOAD",
        ALL_DOCUMENTS: "ALL_DOCUMENTS",
        AGENDAS: "AGENDAS",
        MINUTES: "MINUTES",
        COMMITTEE_DOC: "COMMITTEE_DOC",
        VOTE_ATTACHMENTS: "VOTE_ATTACHMENTS",
        BALLOT_QUEUE: "BALLOT_QUEUE",
        CLOSING_REPORTS: "CLOSING_REPORTS",
        BALLOT_ITEM: "BALLOT_ITEM",
        LITIGATION_HOLD: "LITIGATION_HOLD",
        COLLABORATION_AREA_DRAFT: "COLLABORATION_AREA_DRAFT",
        MANAGE_ALERTS: 'MANAGE_ALERTS',
    },
    // Create Unique Key For page Routes
    uniqueKey: function (page, privilege) {
        if (page === void 0) { page = ""; }
        if (privilege === void 0) { privilege = ""; }
        // Page Will Generate Combination of Page Name and Permission its self.
        // Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]
        return "".concat(page, "-").concat(privilege);
    },
};
// Set All Page Configuration
export var HTTP_METHOD = PAGE_CONFIG.HTTP_METHOD, PAGE_TYPE = PAGE_CONFIG.PAGE_TYPE, USER_PRIVILEGE = PAGE_CONFIG.USER_PRIVILEGE, uniqueKey = PAGE_CONFIG.uniqueKey;
export var NAVIGATION = [
    (_a = {
            ModuleName: "Home",
            ModuleKeys: MODULES[0],
            iconClass: "",
            isShow: false, // For Toggle Navgation if Required
            hasNavChild: true, // For Check Has Page Exist
            routeUrl: "#",
            isVisibleOnNavigation: false
        },
        _a[MODULES[0]] = [
            {
                // DashBoard View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "/",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                oslMethod: "",
                uniqueKey: uniqueKey(PAGE_TYPE.HOME, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _a),
    (_b = {
            ModuleName: "Member",
            ModuleKeys: MODULES[1],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _b[MODULES[1]] = [
            {
                // Member List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/member-management/members"),
                isVisibleOnNavigation: true,
                title: "Members",
                breadcrumbsTitle: "Members",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Organization List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/member-management/organizations"),
                isVisibleOnNavigation: true,
                title: "Organizations",
                breadcrumbsTitle: "Organizations",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Fee Group List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/member-management/fee-group"),
                isVisibleOnNavigation: true,
                title: "Fee Group",
                breadcrumbsTitle: "Fee Group",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/feeGroup/*",
                uniqueKey: uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
        ],
        _b),
    (_c = {
            ModuleName: "Committee",
            ModuleKeys: MODULES[2],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/committee-management/committees"),
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _c[MODULES[2]] = [
            {
                // Committee List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "committee-management/committees",
                isVisibleOnNavigation: false,
                title: "Committee Management",
                breadcrumbsTitle: "Committees",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/committeeManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
            },
        ],
        _c),
    (_d = {
            ModuleName: "Renewal Tasks",
            ModuleKeys: MODULES[3],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/renewal-tasks"),
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _d[MODULES[3]] = [
            {
                // Renewal List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "renewal-tasks",
                isVisibleOnNavigation: false,
                title: "Renewal Tasks",
                breadcrumbsTitle: "Renewal Tasks",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/task/*",
                uniqueKey: uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
            },
        ],
        _d),
    (_e = {
            ModuleName: "Work Item",
            ModuleKeys: MODULES[4],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/work-item-admin"),
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _e[MODULES[4]] = [
            {
                // Work Item Admin Tools page
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "work-item-admin",
                isVisibleOnNavigation: false,
                title: "Work Item Admin Tool",
                breadcrumbsTitle: "Work Item Admin Tool",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/workItemAdmin/*",
                uniqueKey: uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _e),
    (_f = {
            ModuleName: "Ballot",
            ModuleKeys: MODULES[5],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            breadcrumbsRootTitle: "Ballot Admin",
            oslUrl: "",
            isVisibleOnNavigation: true
        },
        _f[MODULES[5]] = [
            {
                // Ballot Admin View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW)],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/ballot-admin"),
                isVisibleOnNavigation: true,
                title: "Ballot Admin",
                breadcrumbsTitle: "Ballot Admin",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/ballotAdmin/*",
                oslMethod: HTTP_METHOD.GET,
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _f),
    (_g = {
            ModuleName: "Admin",
            ModuleKeys: MODULES[6],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _g[MODULES[6]] = [
            {
                // User Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/user"),
                isVisibleOnNavigation: true,
                title: "User Permissions",
                breadcrumbsTitle: "Users",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/usermanagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Role List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/roles"),
                isVisibleOnNavigation: true,
                title: "Roles",
                breadcrumbsTitle: "Roles",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/roledetail/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Membership Renewal [View] Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/membership-renewal"),
                isVisibleOnNavigation: true,
                title: "Membership Renewal",
                breadcrumbsTitle: "Membership Renewal",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberShipRenewal/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Manual Order View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/manual-order"),
                isVisibleOnNavigation: true,
                title: "Manual Order",
                breadcrumbsTitle: "Manual Order",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/addMember/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View MEMBER EXCEPTION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/membership-exception"),
                isVisibleOnNavigation: true,
                title: "Membership Exceptions",
                breadcrumbsTitle: "Membership Exceptions",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/membership-exception/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View BOS VOLUME LOCATION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/bos-volume-location"),
                isVisibleOnNavigation: true,
                title: "BOS Volume Location",
                breadcrumbsTitle: "BOS Volume Location",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/bosVolume/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/manage-alerts"),
                isVisibleOnNavigation: true,
                title: 'Manage Alerts',
                breadcrumbsTitle: "Manage Alerts",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/manageAlerts/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _g),
    (_h = {
            ModuleName: "Reports",
            ModuleKeys: MODULES[7],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _h[MODULES[7]] = [
            {
                // Memberships Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/membership-reports"),
                isVisibleOnNavigation: true,
                title: "Membership Reports",
                breadcrumbsTitle: "Membership Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/membership-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Renewal Report View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/renewal-reports"),
                isVisibleOnNavigation: true,
                title: "Renewal Reports",
                breadcrumbsTitle: "Renewal Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/renewal-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Meeting Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/meeting-reports"),
                isVisibleOnNavigation: true,
                title: "Meeting Reports",
                breadcrumbsTitle: "Meeting Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/meeting-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _h),
];
export var NAVIGATIONHEADER = [
    (_j = {
            ModuleName: "Home",
            ModuleKeys: MODULES[0],
            iconClass: "",
            isShow: false, // For Toggle Navgation if Required
            hasNavChild: true, // For Check Has Page Exist
            routeUrl: "#",
            isVisibleOnNavigation: false
        },
        _j[MODULES[0]] = [
            {
                // DashBoard View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "/",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                oslMethod: "",
                uniqueKey: uniqueKey(PAGE_TYPE.HOME, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _j),
    (_k = {
            ModuleName: "Member",
            ModuleKeys: MODULES[1],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _k[MODULES[1]] = [
            {
                // Member List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/member-management/members"),
                isVisibleOnNavigation: true,
                title: "Members",
                breadcrumbsTitle: "Members",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Organization List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/member-management/organizations"),
                isVisibleOnNavigation: true,
                title: "Organizations",
                breadcrumbsTitle: "Organizations",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Fee Group List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/member-management/fee-group"),
                isVisibleOnNavigation: true,
                title: "Fee Group",
                breadcrumbsTitle: "Fee Group",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/feeGroup/*",
                uniqueKey: uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
        ],
        _k),
    (_l = {
            ModuleName: "Committee",
            ModuleKeys: MODULES[2],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/committee-management/committees"),
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _l[MODULES[2]] = [
            {
                // Committee List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "committee-management/committees",
                isVisibleOnNavigation: false,
                title: "Committee Management",
                breadcrumbsTitle: "Committees",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/committeeManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
            },
        ],
        _l),
    (_m = {
            ModuleName: "Renewal Tasks",
            ModuleKeys: MODULES[3],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/renewal-tasks"),
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _m[MODULES[3]] = [
            {
                // Renewal List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "renewal-tasks",
                isVisibleOnNavigation: false,
                title: "Renewal Tasks",
                breadcrumbsTitle: "Renewal Tasks",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/task/*",
                uniqueKey: uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
            },
        ],
        _m),
    (_o = {
            ModuleName: "Work Item",
            ModuleKeys: MODULES[4],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/work-item-admin"),
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _o[MODULES[4]] = [
            {
                // Work Item Admin Tools page
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "work-item-admin",
                isVisibleOnNavigation: false,
                title: "Work Item Admin Tool",
                breadcrumbsTitle: "Work Item Admin Tool",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/workItemAdmin/*",
                uniqueKey: uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _o),
    (_p = {
            ModuleName: "Ballot",
            ModuleKeys: MODULES[5],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            breadcrumbsRootTitle: "Ballot Admin",
            oslUrl: "",
            isVisibleOnNavigation: true
        },
        _p[MODULES[5]] = [
            {
                // Ballot Admin View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW)],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/ballot-admin"),
                isVisibleOnNavigation: true,
                title: "Ballot Admin",
                breadcrumbsTitle: "Ballot Admin",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/ballotAdmin/*",
                oslMethod: HTTP_METHOD.GET,
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _p),
    (_q = {
            ModuleName: "Admin",
            ModuleKeys: MODULES[6],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _q[MODULES[6]] = [
            {
                // User Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/user"),
                isVisibleOnNavigation: true,
                title: "User Permissions",
                breadcrumbsTitle: "Users",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/usermanagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Role List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/roles"),
                isVisibleOnNavigation: true,
                title: "Roles",
                breadcrumbsTitle: "Roles",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/roledetail/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Membership Renewal [View] Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/membership-renewal"),
                isVisibleOnNavigation: true,
                title: "Membership Renewal",
                breadcrumbsTitle: "Membership Renewal",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberShipRenewal/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Manual Order View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/manual-order"),
                isVisibleOnNavigation: true,
                title: "Manual Order",
                breadcrumbsTitle: "Manual Order",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/addMember/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View MEMBER EXCEPTION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/membership-exception"),
                isVisibleOnNavigation: true,
                title: "Membership Exceptions",
                breadcrumbsTitle: "Membership Exceptions",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/membership-exception/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View BOS VOLUME LOCATION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/bos-volume-location"),
                isVisibleOnNavigation: true,
                title: "BOS Volume Location",
                breadcrumbsTitle: "BOS Volume Location",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/bosVolume/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/admin/manage-alerts"),
                isVisibleOnNavigation: true,
                title: 'Manage Alerts',
                breadcrumbsTitle: "Manage Alerts",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/manageAlerts/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _q),
    (_r = {
            ModuleName: "Reports",
            ModuleKeys: MODULES[7],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _r[MODULES[7]] = [
            {
                // Memberships Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/membership-reports"),
                isVisibleOnNavigation: true,
                title: "Membership Reports",
                breadcrumbsTitle: "Membership Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/membership-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Renewal Report View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/renewal-reports"),
                isVisibleOnNavigation: true,
                title: "Renewal Reports",
                breadcrumbsTitle: "Renewal Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/renewal-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Meeting Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "".concat(process.env.REACT_APP_IA_APP_URL, "/meeting-reports"),
                isVisibleOnNavigation: true,
                title: "Meeting Reports",
                breadcrumbsTitle: "Meeting Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/meeting-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _r),
    (_s = {
            ModuleName: "Documents",
            ModuleKeys: MODULES[8],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "./docupload",
            isExternalUrl: false,
            isVisibleOnNavigation: true
        },
        _s[MODULES[8]] = [
            {
                // Document Dashboard Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.DASHBOARD,
                isVisibleOnNavigation: true,
                title: "Dashboard",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                uniqueKey: uniqueKey(PAGE_TYPE.DASHBOARD, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isStatic: true,
            },
            {
                // Document Upload Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD,
                isVisibleOnNavigation: true,
                title: "Upload",
                breadcrumbsTitle: "Upload",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: [
                    { url: "/documents/agenda/uploadDocument/*", verb: HTTP_METHOD.PUT },
                    { url: "/documents/minutes/uploadDocument/*", verb: HTTP_METHOD.PUT },
                    { url: "/documents/committeeDocuments/uploadDocument/*", verb: HTTP_METHOD.PUT },
                    { url: "/document/voteAttachment/*", verb: HTTP_METHOD.PUT },
                    { url: "/document/ballotDocuments/*", verb: HTTP_METHOD.PUT },
                ],
                uniqueKey: uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.CLOSING_REPORTS, USER_PRIVILEGE.VIEW)],
                searchParams: null,
                isStatic: true,
            },
            {
                // Ballot Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.BALLOT,
                isVisibleOnNavigation: true,
                title: "Ballot Folders",
                breadcrumbsTitle: "Ballot Folders",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [],
                searchParams: null,
                isStatic: true,
                level: -1,
            },
            {
                // Litigation Hold Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD,
                isVisibleOnNavigation: true,
                title: "Litigation Hold",
                breadcrumbsTitle: "Litigation Hold",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/manageLitigation/*",
                uniqueKey: uniqueKey(PAGE_TYPE.LITIGATION_HOLD, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.LITIGATION_HOLD, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.LITIGATION_HOLD, USER_PRIVILEGE.DELETE)],
                searchParams: null,
                isStatic: true,
                level: -1,
            },
            {
                // Litigation Hold Permission
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD,
                isVisibleOnNavigation: true,
                title: "Litigation Hold",
                breadcrumbsTitle: "Litigation Hold",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/manageLitigation/*",
                uniqueKey: uniqueKey(PAGE_TYPE.LITIGATION_HOLD, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false
            },
            {
                // Litigation Hold Permission
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD,
                isVisibleOnNavigation: true,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/manageLitigation/*",
                uniqueKey: uniqueKey(PAGE_TYPE.LITIGATION_HOLD, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
                isStatic: false
            },
            {
                // All Document
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.ALL_DOCUMENTS,
                isVisibleOnNavigation: true,
                title: "All Documents",
                breadcrumbsTitle: "All Documents",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: [
                    { url: "/document/minutes/*", verb: HTTP_METHOD.POST },
                    { url: "/document/agendas/*", verb: HTTP_METHOD.POST },
                    { url: "/document/committeeDocuments/*", verb: HTTP_METHOD.POST },
                    { url: "/document/closingReport/*", verb: HTTP_METHOD.POST },
                    { url: "/document/ballotDocuments/*", verb: HTTP_METHOD.GET },
                    { url: "/document/voteAttachment/*", verb: HTTP_METHOD.POST },
                    { url: "/manageCollabAreaDraft/*", verb: HTTP_METHOD.POST },
                ],
                uniqueKey: uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.CLOSING_REPORTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.DELETE), uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.DELETE), uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.DELETE), uniqueKey(PAGE_TYPE.COLLABORATION_AREA_DRAFT, USER_PRIVILEGE.VIEW),],
                searchParams: null,
            },
            {
                // Submit Agendas Document
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/documents/agenda/uploadDocument/*",
                uniqueKey: uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Submit Minutes Document
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/documents/minutes/uploadDocument/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Submit Committee Document
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/documents/committeeDocuments/uploadDocument/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Submit Vote Attachment
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/voteAttachment/*",
                uniqueKey: uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Submit Ballot Queue
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Submit Ballot Item
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Delete Agendas Document
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/agendas/*",
                uniqueKey: uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Delete Minutes Document
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/minutes/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Delete Committee Document
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/committeeDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Delete Ballot Queue Document
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.ALL_DOCUMENTS, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
                isStatic: false,
            },
            {
                // Document Agendas Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.AGENDAS,
                isVisibleOnNavigation: true,
                title: "Agendas",
                breadcrumbsTitle: "Agendas",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/agendas/*",
                uniqueKey: uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.AGENDAS, USER_PRIVILEGE.DELETE)],
                searchParams: null,
                level: 2,
            },
            {
                // Document Minutes Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.MINUTES,
                isVisibleOnNavigation: true,
                title: "Minutes",
                breadcrumbsTitle: "Minutes",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/minutes/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.MINUTES, USER_PRIVILEGE.DELETE)],
                searchParams: null,
                level: 2,
            },
            {
                // Document CommitteeDocuments Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.COMMITTES,
                isVisibleOnNavigation: true,
                title: "Committee Documents",
                breadcrumbsTitle: "Documents CommitteeDocuments",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/committeeDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.DELETE)],
                searchParams: null,
                level: 2,
            },
            {
                // Document Ballot Queue Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE,
                isVisibleOnNavigation: true,
                title: "Ballot Queue",
                breadcrumbsTitle: "Ballot Queue",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [],
                searchParams: null,
                level: 2,
            },
            {
                // Ballot Item Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS,
                isVisibleOnNavigation: true,
                title: "Ballot Items",
                breadcrumbsTitle: "Ballot Items",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [],
                searchParams: null,
                level: 2,
            },
            {
                // Document VoteAttachment Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS,
                isVisibleOnNavigation: true,
                title: "Vote Attachments",
                breadcrumbsTitle: "Vote Attachments",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/voteAttachment/*",
                uniqueKey: uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.DELETE), uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.UPDATE)],
                searchParams: null,
                level: 2,
            },
            {
                // Document ClosingReport Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS,
                isVisibleOnNavigation: true,
                title: "Closing Reports",
                breadcrumbsTitle: "Closing Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/closingReport/*",
                uniqueKey: uniqueKey(PAGE_TYPE.CLOSING_REPORTS, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [uniqueKey(PAGE_TYPE.CLOSING_REPORTS, USER_PRIVILEGE.DELETE)],
                searchParams: null,
                level: 2,
            },
            {
                // Delete Document ClosingReport Permission
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.CLOSING_REPORTS, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "Closing Reports",
                breadcrumbsTitle: "Closing Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/closingReport/*",
                uniqueKey: uniqueKey(PAGE_TYPE.CLOSING_REPORTS, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Delete Document Vote attachement Permission
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "Closing Reports",
                breadcrumbsTitle: "Closing Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/voteAttachment/*",
                uniqueKey: uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Update Document Vote attachement Permission
                permissionType: USER_PRIVILEGE.UPDATE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "Vote Attachments",
                breadcrumbsTitle: "Vote Attachments",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/voteAttachment/*",
                uniqueKey: uniqueKey(PAGE_TYPE.VOTE_ATTACHMENTS, USER_PRIVILEGE.UPDATE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Delete ballot queue Document Permission
                permissionType: USER_PRIVILEGE.DELETE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.DELETE)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "ballot Queue",
                breadcrumbsTitle: "Ballot Queue",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.DELETE),
                oslMethod: HTTP_METHOD.DELETE,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Resequence ballot Item  Permission
                permissionType: USER_PRIVILEGE.SEQUENCE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.BALLOT_ITEM, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "ballot Item Resequence",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotItemSequence/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.SEQUENCE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Resequence Committee Doc Item  Permission
                permissionType: USER_PRIVILEGE.SEQUENCE,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "Committee Document Resequence",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/committeeDocuments/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE_DOC, USER_PRIVILEGE.SEQUENCE),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Publish ballot Folder  Permission
                permissionType: USER_PRIVILEGE.PUBLISH,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.BALLOT_ITEM, USER_PRIVILEGE.VIEW)],
                routeUrl: "",
                isVisibleOnNavigation: false,
                title: "ballot folder Publish",
                breadcrumbsTitle: "",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/document/ballotItemPublish/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.PUBLISH),
                oslMethod: HTTP_METHOD.PUT,
                additionalPermission: [],
                searchParams: null,
            },
            {
                // Document Collaboration Area Draft Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT,
                isVisibleOnNavigation: true,
                title: "Collaboration Area Draft",
                breadcrumbsTitle: "Collaboration Area Draft",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/manageCollabAreaDraft/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COLLABORATION_AREA_DRAFT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.POST,
                additionalPermission: [],
                searchParams: null,
                level: 2,
            },
        ],
        _s),
];
