import axios from "axios";
import { cacheManager, callAPI, clearAzureAdAuthCodeAction, cookiesManager, getApiUniqueRequestId, noCookiesRedirectToLogin } from "./helpers/util-common";
import * as types from "./action-type";
import getApiUrl from "./helpers/api-urls";
import { decrypt, encrypt } from "./helpers/util-common";
import { cookieKeysEnum } from "./models/common.models";
// Commented For Future Use
// export const hideMessage = (dispatch: any) => {
//   dispatch(setMessage(false, "200"));
// };
export var setLoader = function (flag) { return ({
    type: types.SET_LOADER,
    flag: flag,
}); };
export var setminiLoader = function (flag) { return ({
    type: types.SET_MINI_LOADER,
    flag: flag,
}); };
export var setMessage = function (flag, code, uniqueRequestId, message) {
    if (uniqueRequestId === void 0) { uniqueRequestId = null; }
    if (message === void 0) { message = ""; }
    return ({
        type: types.SET_MESSAGE,
        flag: flag,
        code: code,
        message: message,
        uniqueRequestId: uniqueRequestId
    });
};
export var showSuccessToastMesage = function (flag, code, message) {
    if (message === void 0) { message = ""; }
    return ({
        type: types.SHOW_SUCCESS_MESSAGE,
        flag: flag,
        code: code,
        message: message
    });
};
export var showDissmissibleToastMesage = function (flag, code, dismissibleMessage, dispatch) {
    if (dismissibleMessage === void 0) { dismissibleMessage = ""; }
    return (dispatch({ type: types.SHOW_DISMISSIBLE_MESSAGE, flag: flag, code: code, dismissibleMessage: dismissibleMessage }));
};
// Commented For Future Use
// export const showErrorPopup = (flag: any, data: any) => ({
//   type: types.SHOW_ERROR_POPUP,
//   flag,
//   data,
// });
export var StorCurrentLocation = function (dispatch, data) {
    dispatch({ type: types.STORE_CURRENT_LOCATION, data: data });
};
export var showHideCreatBallotFolderModelAction = function (dispatch, flag) {
    dispatch({ type: types.SHOW_CREATE_BALLOT_FOLDER, flag: flag });
};
export var showHideBallotManulaPublish = function (dispatch, flag) {
    dispatch({ type: types.SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH, flag: flag });
};
export var setManualZipStatusInfo = function (dispatch, ballotManualZipInfo) {
    dispatch({ type: types.MANUAL_ZIP_STATUS_INFO, ballotManualZipInfo: ballotManualZipInfo });
};
//Commented For Future Use
// export const showAlertPopup = (flag: any, data: any) => ({
//   type: types.SHOW_ALERT_POPUP,
//   flag,
//   data,
// });
// Action for calling logout.
export var logout = function (dispatch) {
    var url = getApiUrl("login", "logout");
    dispatch(setLoader(true));
    axios
        .post(url)
        .then(function (response) {
        if (response.data.status) {
            // Clear all cache from react app.
            cacheManager.clearSession();
            // Validate if Logout from Azure AD.
            if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
                cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
                setCurrentLocationStorage();
                setTimeout(function () {
                    window.location.assign(clearAzureAdAuthCodeAction());
                }, 500);
            }
            else {
                window.location.assign("/login");
            }
        }
        else {
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(setMessage(true, response.data.message, uniqueRequestId));
        }
        dispatch(setLoader(false));
    })
        .catch(function (err) {
        dispatch(setLoader(false));
        // Custom default code for autologout
        noCookiesRedirectToLogin();
    });
};
export var currentLocationStorage = function () {
    if (cacheManager.getItem("current-location")) {
        return { isLocation: true, location: cacheManager.getItem("current-location") };
    }
    return { isLocation: false, location: null };
};
export var setCurrentLocationStorage = function () {
    if (window.location.href) {
        var location_1 = window.location.href.replace(/.*\/\/[^\/]*/, "");
        var allowedRoutes = ["/renewal-tasks", "/admin", "/user", "/roles", "/member-management", "/committee-management", "/work-item-admin", "/ballot-admin"];
        var isAllowed = allowedRoutes.filter(function (x) { return location_1.includes(x); });
        if (isAllowed && isAllowed.length) {
            cacheManager.setItem("current-location", window.location.href.replace(/.*\/\/[^\/]*/, ""));
        }
    }
};
export var getPermissionOnPageAction = function (Input, groupInput) { return ({
    type: types.ALLOW_PERMISSION_FOR_PAGE,
    Input: Input,
    groupInput: groupInput,
}); };
// TODO : initiate Azure Request
export var initiateAzureRequestAction = function (code, sessionState, authState, cb, dispatch) {
    // TODO : Start code for init azure request.
    dispatch(setLoader(true));
    // TODO : Call Request for Init Azure in OSL.
    var url = getApiUrl("login", "initAzure");
    var authCode = encrypt("".concat(code, ":").concat(sessionState, ":").concat(decrypt(decodeURIComponent(authState))));
    callAPI(url, "post", { code: authCode }, function (response) {
        if (response.data && response.data.success) {
            cookiesManager.setCookie(cookieKeysEnum.APP_SESSIONID, sessionState);
            cb(true);
        }
        else {
            dispatch(setLoader(false));
            cb(false);
        }
    });
};
export var flagFeature = function (callback, dispatch) {
    var url = getApiUrl("openAPI", "flagFeature", true);
    dispatch(setLoader(true));
    callAPI(url, "get", null, function (response) {
        dispatch(setLoader(false));
        if (response.data && response.data.Status) {
            callback(response);
        }
        else {
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(setMessage(true, response.data.Message || "8166", uniqueRequestId));
        }
    });
};
export var getBallotListAction = function (isFiltered, dispatch, callback) {
    dispatch(setLoader(true));
    var url = getApiUrl("ballotAdmin", "getBallotDetailsByLetterBallot");
    url = "".concat(url, "/").concat(isFiltered);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(setLoader(false));
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            dispatch(setMessage(true, "7025", uniqueRequestId));
        }
    })
        .catch(function (err) {
        dispatch(setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(setMessage(true, "7025", uniqueRequestId));
    });
};
export var getDocumentStatusByCategoryAction = function (dispatch, categoryId, callback, isCallback) {
    if (isCallback === void 0) { isCallback = false; }
    dispatch(setLoader(true));
    var url = getApiUrl("documents", "getDocumentStatusByCategory");
    url = "".concat(url, "/").concat(categoryId);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(setLoader(false));
        if (response.data.status) {
            dispatch({ type: types.GET_DOCUMENT_STATUS_BY_CATEGORY, data: response.data.content });
            if (isCallback) {
                callback(response.data.content);
            }
        }
        else {
            dispatch(setMessage(true, "7025", uniqueRequestId));
        }
    })
        .catch(function (err) {
        dispatch(setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(setMessage(true, "7025", uniqueRequestId));
    });
};
export var setFlagForBallotNavigation = function (ballotFlag) { return ({
    type: types.SET_BALLOT_FLAG,
    ballotFlag: ballotFlag
}); };
export default {
    // hideMessage,
    setLoader: setLoader,
    setMessage: setMessage,
    showSuccessToastMesage: showSuccessToastMesage,
    // showErrorPopup,
    StorCurrentLocation: StorCurrentLocation,
    // showAlertPopup,
    logout: logout,
    getPermissionOnPageAction: getPermissionOnPageAction,
    currentLocationStorage: currentLocationStorage,
    setCurrentLocationStorage: setCurrentLocationStorage,
    initiateAzureRequestAction: initiateAzureRequestAction,
    getDocumentStatusByCategoryAction: getDocumentStatusByCategoryAction,
    showHideCreatBallotFolderModelAction: showHideCreatBallotFolderModelAction,
    setFlagForBallotNavigation: setFlagForBallotNavigation
};
