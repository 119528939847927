import axios from 'axios';
import lodash from 'lodash';
import * as type from '../../action-type';
import * as commonActions from '../../common.actions';
import getApiUrl from '../../helpers/api-urls';
import * as utilCommon from '../../helpers/util-common';
export var authenticateByAzureADAction = function (dispatch, callback) {
    var url = getApiUrl('login', 'authenticateByAzureAD');
    dispatch(commonActions.setLoader(true));
    axios.get(url).then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (lodash.get(response.data, 'success')) {
            utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
            dispatch({ type: type.AZURE_LOGIN, payload: response });
            // Return true if get accessToken from OSL
            callback(true, lodash.get(response.data, 'message'));
        }
        else {
            utilCommon.setLoginDataToCacheManager(response, 'DELETE');
            callback(false, lodash.get(response.data, 'message'));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = utilCommon.getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '1012', uniqueRequestId));
    });
};
// Auth by Cookies token
export var authByCookiesTokenAction = function (dispatch, inputDTO, callback) {
    var url = getApiUrl('login', 'authCookiesToken');
    dispatch(commonActions.setLoader(true));
    axios.post(url, { source: inputDTO.appId }).then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (lodash.get(response.data, 'success')) {
            utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
            // Return true if get accessToken from OSL
            callback(true, lodash.get(response.data, 'message'));
        }
        else {
            utilCommon.setLoginDataToCacheManager(response, 'DELETE');
            callback(false, lodash.get(response.data, 'message'));
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
    });
};
export var checkSessionInfo = function (dispatch, callback) {
    var url = getApiUrl('login', 'checkSessionInfo');
    dispatch(commonActions.setLoader(true));
    axios.get(url).then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (lodash.get(response.data, 'success')) {
            //utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
            // Return true if get accessToken from OSL
            callback(response.data, lodash.get(response.data, 'message'));
        }
        else {
            //utilCommon.setLoginDataToCacheManager(response, 'DELETE');
            callback(false, lodash.get(response.data, 'message'));
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
    });
};
export default {
    authenticateByAzureADAction: authenticateByAzureADAction,
    authByCookiesTokenAction: authByCookiesTokenAction,
    checkSessionInfo: checkSessionInfo
};
