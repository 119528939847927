import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';
import commonReducer from './common-reducer';
import documentUploadReducer from './document-upload-reducer';
import loginReducer from '../../models/login/login.reducer';
import allDocumentsReducer from './all-documents-reducer';
/** Add Multiple Reducer */
var reducers = {
    i18nReducer: i18nReducer,
    form: form,
    commonReducer: commonReducer,
    documentUploadReducer: documentUploadReducer,
    loginReducer: loginReducer,
    allDocumentsReducer: allDocumentsReducer
};
export default combineReducers(reducers);
