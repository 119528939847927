import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import logoImg from "../../assets/images/astm-internal.png";
import React from "react";
import lodash from "lodash";
import { Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import NavigationMenu from "../NavigationMenu";
import { useDispatch } from "react-redux";
import { logout } from "../../common.actions";
export var HeaderMenu = function (props) {
    var dispatch = useDispatch();
    var applogout = function () {
        logout(dispatch);
    };
    return (_jsxs("header", { "data-testid": "headertest", children: [_jsx("div", { className: "container", children: _jsxs("div", { className: "headerWrap", children: [_jsx("div", { className: "logoWrap height-34", children: _jsx("a", { href: "", children: _jsx(Image, { src: logoImg, alt: "ASTM Logo" }) }) }), _jsx("div", { className: "headerFilterhWrap", children: _jsx("div", { className: "profileOptionMenu dropdown", children: _jsxs(Dropdown, { children: [_jsxs(Dropdown.Toggle, { variant: "success", id: "dropdown-basic", className: "btn astm-btn btn-primary yellow-btn", children: [_jsx("i", { className: "fas fa-user-circle astm-icon" }), " ", props.userDetail ? lodash(props.userDetail).get("userName", "").toLocaleUpperCase() : ""] }), props.userDetail ? (_jsx(Dropdown.Menu, { children: _jsx(Dropdown.Item, { "data-testid": "logout-id", onClick: applogout, children: "Logout" }) })) : ("")] }) }) })] }) }), _jsx(NavigationMenu, { history: props.history })] }));
};
export default HeaderMenu;
