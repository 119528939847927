import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import CustomLoader from "../shared-components/Loader";
import { customeRoutes } from "./lazy-load";
import { LEFT_NAVIGATION_PAGE_URL } from "../models/navigation.models";
var Navigation = function (props) {
    var history = props.history;
    return (_jsx("div", { children: _jsx(Suspense, { fallback: _jsx(CustomLoader, {}), children: _jsxs(Routes, { children: [_jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.DASHBOARD, element: _jsx(customeRoutes.DashBoard, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD, element: _jsx(customeRoutes.DocumentsUpload, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOT, element: _jsx(customeRoutes.Ballot, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.ALL_DOCUMENTS, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.AGENDAS, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.MINUTES, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.COMMITTES, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT, element: _jsx(customeRoutes.AllDocuments, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD, element: _jsx(customeRoutes.LitigationHold, {}) }), _jsx(Route, { path: LEFT_NAVIGATION_PAGE_URL.BALLOT, element: NotFound() })] }) }) }));
};
function NotFound() {
    return (_jsx(_Fragment, {}));
}
export default Navigation;
