// import { createStore, applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './reducer/root-reducer';
import { i18nActions } from 'redux-react-i18n';
import { translations } from '../translations';
var dictionaries = translations;
var languages = [
    {
        code: 'en-US',
        name: 'English (USA)',
    },
];
export var store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});
store.dispatch(i18nActions.setDictionaries(dictionaries));
store.dispatch(i18nActions.setLanguages(languages));
store.dispatch(i18nActions.setCurrentLanguage('en-US'));
