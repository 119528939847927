var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { datadogLogs } from "@datadog/browser-logs";
export var LOG_TYPE = {
    debug: "debug",
    info: "info",
    warn: "warn",
    error: "error",
};
export var logger = function (message, obj, status, error) {
    if (obj === void 0) { obj = {}; }
    if (status === void 0) { status = LOG_TYPE.debug; }
    var genricObj = __assign(__assign({}, obj), { date: new Date().toDateString() });
    datadogLogs.logger.log(message, genricObj, status, error);
};
