var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import "./src/index.css";
import App from "./src/App";
import reportWebVitals from "./src/reportWebVitals";
import { ViewportProvider } from "./src/hooks/useViewPort";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";
import setupInterceptors from "./src/interceptor";
import ErrorBoundary from "./src/shared-components/ErrorBoundary";
datadogLogs.init({
    clientToken: (_a = process.env.REACT_APP_DATADOG_CLIENT_TOKEN) !== null && _a !== void 0 ? _a : "",
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
});
var root = ReactDOM.createRoot(document.getElementById("root"));
// Configure Store
setupInterceptors(store);
root.render(_jsx(ErrorBoundary, { children: _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(ViewportProvider, { children: _jsx(App, {}) }) }) }) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
