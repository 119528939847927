// Login
export var LOGIN = "LOGIN";
export var AZURE_LOGIN = "AZURE_LOGIN";
export var AZURE_REFRESH_TOKEN = "AZURE_REFRESH_TOKEN";
// Common
export var SET_MESSAGE = "SET_MESSAGE";
export var SET_LOADER = "SET_LOADER";
export var SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
export var SHOW_ALERT_POPUP = "SHOW_ALERT_POPUP";
export var SET_MINI_LOADER = "SET_MINI_LOADER";
export var SHOW_NOT_FOUND_PAGE = "SHOW_NOT_FOUND_PAGE";
export var HIDE_NOT_FOUND_PAGE = "HIDE_NOT_FOUND_PAGE";
export var GET_APP_USER_LIST = "GET_APP_USER_LIST";
export var IS_SAFARI_AUTOFILL_VISISBLE = "IS_SAFARI_AUTOFILL_VISISBLE";
export var CLICK_DOCUMENT_INFO_ICON = "CLICK_DOCUMENT_INFO_ICON";
export var STORE_CURRENT_LOCATION = "STORE_CURRENT_LOCATION";
export var UPDATE_DOCUMENT_LIST = "UPDATE_DOCUMENT_LIST";
export var GET_DOCUMENT_STATUS_BY_CATEGORY = "GET_DOCUMENT_STATUS_BY_CATEGORY";
export var SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export var SHOW_DISMISSIBLE_MESSAGE = "SHOW_DISMISSIBLE_MESSAGE";
export var GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export var GET_US_STATE_LIST = "GET_US_STATE_LIST";
export var GET_CANADA_STATE_LIST = "GET_CANADA_STATE_LIST";
// Permission Action
export var ALLOW_PERMISSION_FOR_PAGE = "ALLOW_PERMISSION_FOR_PAGE";
//Document Upload
export var DOCUMENT_CATEGORY = "DOCUMENT_CATEGORY";
export var FILE_NAME = "FILE_NAME";
export var FAILED_FILE_NAME_LIST = "FAILED_FILE_NAME_LIST";
// Ballo Folder
export var SHOW_CREATE_BALLOT_FOLDER = "SHOW_CREATE_BALLOT_FOLDER";
export var SET_BALLOT_FLAG = "SET_BALLOT_FLAG";
export var SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH = "SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH";
export var MANUAL_ZIP_STATUS_INFO = "MANUAL_ZIP_STATUS_INFO";
export var BALLOT_FIXED_HEADER = "BALLOT_FIXED_HEADER";
export var SET_LITIGATION_MODAL = "SET_LITIGATION_MODAL";
