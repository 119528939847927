var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import * as types from "../../action-type";
import commonActions, { setminiLoader } from "../../common.actions";
import { cacheManager, getApiUniqueRequestId } from "../../helpers/util-common";
import getApiUrl from "../../helpers/api-urls";
import { LEFT_NAVIGATION_PAGE_URL } from "../../models/navigation.models";
import { categoryIdForDocuments } from "../../models/common.models";
/** ACTION FOR CALLING ALL DOCUMENTS LIST */
export var getAllDocumentsListAction = function (dispatch, data, url, callback) {
    var uploadedFileName = cacheManager.getItem("fileName") && JSON.parse(cacheManager.getItem("fileName"));
    var uploadAsTermsDoc = cacheManager.getItem("uploadTermDocument") && cacheManager.getItem("uploadTermDocument");
    if (uploadedFileName) {
        dispatch({ type: types.FILE_NAME, data: uploadedFileName.length > 0 ? uploadedFileName : uploadedFileName.fileName });
        if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.AGENDAS)) {
            dispatch(commonActions.setMessage(true, uploadedFileName.length > 0 ? "2040" : "2004"));
        }
        else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.MINUTES)) {
            dispatch(commonActions.setMessage(true, uploadedFileName.length > 0 ? "2041" : "2007"));
        }
        else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.COMMITTES)) {
            dispatch(commonActions.setMessage(true, "2014"));
        }
        else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE)) {
            dispatch(commonActions.setMessage(true, "2030"));
        }
        else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS)) {
            JSON.parse(uploadAsTermsDoc) ? dispatch(commonActions.setMessage(true, "2034")) : dispatch(commonActions.setMessage(true, "2033"));
        }
        else {
            dispatch(commonActions.setMessage(true, "2023"));
        }
    }
    cacheManager.removeItem("fileName");
    cacheManager.removeItem("uploadTermDocument");
    dispatch(setminiLoader(true));
    axios
        .post(url, data)
        .then(function (response) {
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            callback({});
        }
    })
        .catch(function () {
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
export var handleDocumentInfoClickAction = function (dispatch, documentId, isDocumentInfo, displayFileName, hasAccess, currentDocumentChangedStatus) {
    dispatch({
        type: types.CLICK_DOCUMENT_INFO_ICON,
        data: { documentId: documentId, isDocumentInfo: isDocumentInfo, displayFileName: displayFileName, hasAccess: hasAccess, currentDocumentChangedStatus: currentDocumentChangedStatus },
    });
};
export var handleDocumentDeleteClickAction = function (dispatch, postData, callback) {
    dispatch(commonActions.setLoader(true));
    var url = "";
    var DocumentCategoryId = postData.DocumentCategoryId;
    if (DocumentCategoryId == categoryIdForDocuments.agenda) {
        url = getApiUrl("documents", "agendasDocDelete");
    }
    else if (DocumentCategoryId == categoryIdForDocuments.minutes) {
        url = getApiUrl("documents", "minutesDocDelete");
    }
    else if (DocumentCategoryId == categoryIdForDocuments.committeesDocuments) {
        url = getApiUrl("documents", "committeeDocDelete");
    }
    else if (DocumentCategoryId == categoryIdForDocuments.voteAttachment) {
        url = getApiUrl("documents", "voteAttachmentDocDelete");
    }
    else if (DocumentCategoryId == categoryIdForDocuments.ballotQueue) {
        url = getApiUrl("documents", "ballotQueueDocDelete") + "/1";
    }
    else if (DocumentCategoryId == categoryIdForDocuments.ballotItems) {
        url = getApiUrl("documents", "ballotQueueDocDelete") + "/3";
    }
    axios
        .delete(url, { data: postData })
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
            dispatch(commonActions.setMessage(true, "2011"));
            dispatch({ type: types.FILE_NAME, data: [] });
        }
        else {
            callback({});
            dispatch(commonActions.setMessage(true, "2010", uniqueRequestId));
        }
    })
        .catch(function (ex) {
        console.log(ex);
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
export var getDocumentInfoByIdAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getDocumentInfoById");
    axios
        .post(url, data)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            callback({});
            // Handle error case
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
        }
    })
        .catch(function () {
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
export var updateDocumentInfoAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var displaySuccessMessage = data.displaySuccessMessage, restData = __rest(data, ["displaySuccessMessage"]);
    var url = getApiUrl("documents", "updateDocumentInfo");
    axios
        .post(url, restData)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            dispatch(commonActions.setMessage(true, "7025", null, displaySuccessMessage));
            dispatch({ type: types.UPDATE_DOCUMENT_LIST, activeDocumentInfoDataId: data.DocumentDataId });
            dispatch({ type: types.FILE_NAME, data: [] });
            callback(response.data.status);
        }
        else {
            // Handle error case
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
        }
    })
        .catch(function () {
        dispatch(commonActions.setLoader(false));
    });
};
export var updateActiveDocumentInfoDataIdAction = function (dispatch) {
    dispatch({ type: types.UPDATE_DOCUMENT_LIST, activeDocumentInfoDataId: 0 });
};
export var getDownloadDocumentFileUrlAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var displaySuccessMessage = data.displaySuccessMessage, restData = __rest(data, ["displaySuccessMessage"]);
    var url = getApiUrl("documents", "downloadDocumentFileUrl");
    axios
        .post(url, restData)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            // Handle error case
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
        }
    })
        .catch(function () {
        dispatch(commonActions.setLoader(false));
    });
};
export var getBallotFolderList = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "getBallotQueueList"), "/2");
    axios
        .post(url, data)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            // Handle error case
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
        }
    })
        .catch(function () {
        dispatch(commonActions.setLoader(false));
    });
};
export var getBallotFolderStatusList = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "ballotFolderStatusList"));
    axios
        .get(url, data)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            // Handle error case
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
        }
    })
        .catch(function () {
        dispatch(commonActions.setLoader(false));
    });
};
export var setFlagForBallotNavigation = function (dispatch, ballotFlag) {
    dispatch(commonActions.setFlagForBallotNavigation(ballotFlag));
};
export var postBallotFolderDetailPage = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "ballotFolderDetailList"));
    axios
        .post(url, data)
        .then(function (response) {
        var _a;
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback((_a = response.data.content) === null || _a === void 0 ? void 0 : _a.Data);
        }
        else {
            // Handle error case
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
        }
    })
        .catch(function () {
        dispatch(commonActions.setLoader(false));
    });
};
export var putBallotItemResequence = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "ballotItemResequence"));
    axios
        .put(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            dispatch(commonActions.setMessage(true, "1400", uniqueRequestId));
        }
    })
        .catch(function () {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1400", uniqueRequestId));
        dispatch(commonActions.setLoader(false));
        callback(false);
    });
};
export var getLetterBallotListAction = function (documentCategoryId, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getLetterBallotList");
    url = "".concat(url, "/").concat(documentCategoryId);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            dispatch(commonActions.setMessage(true, "1202", uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1202", uniqueRequestId));
        dispatch(commonActions.setLoader(false));
    });
};
export var getBallotFolderZipFileStatus = function (ballotId, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getBallotFolderZipFileStatus");
    url = "".concat(url, "/").concat(ballotId);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            dispatch(commonActions.setMessage(true, "1312", uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1312", uniqueRequestId));
        dispatch(commonActions.setLoader(false));
    });
};
export var getRestrictDocumentDeletionAction = function (documentDataId, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getRestrictDocumentDeletion");
    url = "".concat(url, "/").concat(documentDataId);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            dispatch(commonActions.setMessage(true, "1515", uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1515", uniqueRequestId));
        dispatch(commonActions.setLoader(false));
    });
};
export var ballotFixedHeaderAction = function (dispatch, flag) {
    dispatch({
        type: types.BALLOT_FIXED_HEADER,
        data: flag,
    });
};
export var putCommitteeDocumentResequence = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "committeeDocResequence"));
    axios
        .put(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            dispatch(commonActions.setMessage(true, "1600", uniqueRequestId));
        }
    })
        .catch(function () {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1600", uniqueRequestId));
        dispatch(commonActions.setLoader(false));
        callback(false);
    });
};
export var handleDeleteBulkBallotQueueAction = function (dispatch, postData, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "deleteBulkBallotQueue");
    axios
        .delete(url, { data: postData })
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
            dispatch(commonActions.setMessage(true, "2011"));
            dispatch({ type: types.FILE_NAME, data: [] });
        }
        else {
            callback({});
            dispatch(commonActions.setMessage(true, "2010", uniqueRequestId));
        }
    })
        .catch(function (ex) {
        console.log(ex);
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
