import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { LEFT_NAVIGATION_PAGE_URL, PAGE_TYPE, USER_PRIVILEGE } from "../models/navigation.models";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import commonActions, { StorCurrentLocation } from "../common.actions";
import { checkUserPermissionOnPage } from "../helpers/util-common";
function Breadcrumb() {
    var dispatch = useDispatch();
    var showballotFolder = function (flag) {
        commonActions.showHideCreatBallotFolderModelAction(dispatch, flag);
    };
    var props = useSelector(function (state) {
        return {
            showCreateBallotFolder: state.commonReducer.showCreateBallotFolderModel,
            userPermission: state.commonReducer.userPermission,
            ballotFlag: state.commonReducer.ballotFlag,
        };
    });
    var storyCurrentLocation = function (location) {
        StorCurrentLocation(dispatch, location);
    };
    var _a = useState(false), showCreateBollotFolder = _a[0], setShowCreateBallotFolder = _a[1];
    var handlerCreateBallotFolder = function () {
        var status = !props.showCreateBallotFolder;
        setShowCreateBallotFolder(status);
        showballotFolder(status);
    };
    var addConditionBreadcrumb = function () {
        var pathName = window.location.pathname;
        switch (pathName) {
            case LEFT_NAVIGATION_PAGE_URL.DASHBOARD:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.DASHBOARD, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.DASHBOARD); }, children: "Dashboard" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD); }, children: "Upload" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.BALLOT:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), props.ballotFlag === 1 ? (_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.BALLOT, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT); }, children: "Ballot Folders" }) })) : (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", "aria-current": "page", children: "Ballot Folders" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.BALLOT, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT); }, children: "Ballot Detail Page" }) })] }))] }));
            case LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD); }, children: "Litigation Hold" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.ALL_DOCUMENTS:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: "All Documents" })] }));
            case LEFT_NAVIGATION_PAGE_URL.AGENDAS:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.AGENDAS, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.AGENDAS); }, children: "Agendas" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.MINUTES:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.MINUTES, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.MINUTES); }, children: "Minutes" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.COMMITTES:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.COMMITTES, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.COMMITTES); }, children: "Committee Documents" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE); }, children: "Ballot Queue" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), props.ballotFlag === 1 ? (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS); }, children: "Ballot Items" }) })] })) : (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Ballot Folders" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS); }, children: "Ballot Detail Page" }) })] }))] }));
            case LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS); }, children: "Vote Attachments" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS); }, children: "Closing Reports" }) })] }));
            case LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "Documents" }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: "All Documents" }), _jsx("li", { className: "breadcrumb-item active", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx(Link, { target: "_self", to: LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT, onClick: function () { return storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT); }, children: "Collaboration Area Draft" }) })] }));
            default:
                return (_jsxs(_Fragment, { children: [_jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", children: _jsx("a", { href: "#", children: "Home" }) }), _jsx("li", { className: "breadcrumb-item", "aria-label": "breadcrumb", "aria-current": "page", children: _jsx("a", { href: "#", children: "Documents" }) })] }));
        }
    };
    return (_jsx("div", { className: "breadcrumb-wrapper", children: _jsxs("div", { className: "container", children: [_jsx("nav", { "aria-label": "breadcrumb", children: _jsx("ol", { className: "breadcrumb astm-breadcrumb", children: addConditionBreadcrumb() }) }), window.location.pathname == LEFT_NAVIGATION_PAGE_URL.BALLOT ? (props.ballotFlag === 1 ? (_jsxs("div", { className: "sub-title-wrap", children: [_jsx("h1", { className: "astm-type-heading--h1", children: "Ballot Folders" }), checkUserPermissionOnPage(props.userPermission, PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.UPDATE) && (_jsxs("button", { type: "button", className: "btn astm-btn btn-secondary", "data-testid": "add-ballot-folder-btn", onClick: function () { return handlerCreateBallotFolder(); }, children: [_jsx("i", { className: "astm-icon far fa-plus" }), " Add ballot folder"] }))] })) : (_jsx("div", { className: "sub-title-wrap", children: _jsx("h1", { className: "astm-type-heading--h1", children: "Ballot Detail Page" }) }))) : window.location.pathname == LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD ? (_jsx("div", { className: "sub-title-wrap", children: _jsx("h1", { className: "astm-type-heading--h1", children: "Upload" }) })) : window.location.pathname == LEFT_NAVIGATION_PAGE_URL.DASHBOARD ? (_jsx("div", { className: "sub-title-wrap", children: _jsx("h1", { className: "astm-type-heading--h1", children: "Dashboard" }) })) : window.location.pathname == LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD ? (_jsx("div", { className: "sub-title-wrap", children: _jsx("h1", { className: "astm-type-heading--h1", children: "Litigation Hold" }) })) : (_jsx("h1", { className: "astm-type-heading--h1", children: props.ballotFlag === 2 ? "Ballot Detail Page" : "All Documents" })), _jsx("div", { className: "solid-border mt16 mb-0" })] }) }));
}
export default Breadcrumb;
