export var apiUrls = {
    baseUrl: process.env.REACT_APP_API_URL,
    localBaseUrl: process.env.REACT_APP_API_URL,
    openApiUrl: process.env.REACT_APP_OPENAPI_URL,
    localOpenApiUrl: process.env.REACT_APP_OPENAPI_URL,
    live: {
        login: {
            login: {
                url: "/login",
                version: "/v1",
                requestType: "POST",
            },
            logout: {
                url: "/logout",
                version: "/v1",
                requestType: "POST",
            },
            authenticateByAzureAD: {
                url: "/authenticateByAzureAD",
                version: "/v1",
                requestType: "GET",
            },
            getAzureADRefreshToken: {
                url: "/getAzureADRefreshToken",
                version: "/v1",
                requestType: "POST",
            },
            authCookiesToken: {
                url: "/authCookiesToken",
                version: "/v1",
                requestType: "POST",
            },
            initAzure: {
                url: "/initAzure",
                version: "/v1",
                requestType: "POST",
            },
            checkSessionInfo: {
                url: "/checkSessionInfo",
                version: "/v1",
                requestType: "GET",
            },
        },
        userPermission: {
            getRoleList: {
                url: "/role/Get",
                version: "/v1",
                requestType: "GET",
            },
            getDefaultPrivilege: {
                url: "/defaultprivilege/Get",
                version: "/v1",
                requestType: "GET",
            },
            getPrivilegeByRole: {
                url: "/privilegebyRole/Get",
                version: "/v1",
                requestType: "GET",
            },
            getPrivilegeByUser: {
                url: "/privilegebyuser/Get",
                version: "/v1",
                requestType: "GET",
            },
            getUserDetail: {
                url: "/userdetail/Get",
                version: "/v1",
                requestType: "GET",
            },
            usermanagement: {
                url: "/usermanagement",
                version: "/v1",
                requestType: "POST",
            },
            getuserPrivilege: {
                url: "/userPrivilege",
                version: "/v1",
                requestType: "GET",
            },
            getuserList: {
                url: "/usermanagement/userlist",
                version: "/v1",
                requestType: "GET",
            },
            getExportUserList: {
                url: "/usermanagement/exportFile/userlist",
                version: "/v1",
                requestType: "GET",
            },
            getAppUserList: {
                url: "/usermanagement/AppUserlist",
                version: "/v1",
                requestType: "GET",
            },
            getAppUserDetailList: {
                url: "/usermanagement/AppUserDetailList",
                version: "/v1",
                requestType: "GET",
            },
            putuserPrivilege: {
                url: "/usermanagement/updatePrivileges",
                version: "/v1",
                requestType: "PUT",
            },
            getUserListOnSearch: {
                url: "/usermanagement/searchuser",
                version: "/v1",
                requestType: "GET",
            },
            getUserValidate: {
                url: "/usermanagement/validateuser",
                version: "/v1",
                requestType: "GET",
            },
            updateUserRole: {
                url: "/usermanagement/UpdateUserRole",
                version: "/v1",
                requestType: "PUT",
            },
            getUserPersonalDetails: {
                url: "/usermanagement/getUserPersonalDetails",
                version: "/v1",
                requestType: "GET",
            },
            getGridUserPreferences: {
                url: "/usermanagement/getGridUserPreferences",
                version: "/v1",
                requestType: "GET",
            },
            updateUserPassword: {
                url: "/usermanagement/updateUserPassword",
                version: "/v1",
                requestType: "PUT",
            },
            getUserAuditLogDetail: {
                url: "/usermanagement/getUserAuditLog",
                version: "/v1",
                requestType: "GET",
            },
            getAuditLogDetail: {
                url: "/roleprivilege/getRoleAuditLog",
                version: "/v1",
                requestType: "GET",
            },
            getAuditLogSearchField: {
                url: "/privileges/GET",
                version: "/v1",
                requestType: "GET",
            },
        },
        manageRole: {
            getRoleDetailList: {
                url: "/roledetail/Get/",
                version: "/v1",
                requestType: "GET",
            },
            postRoleDetail: {
                url: "/roledetail",
                version: "/v1",
                requestType: "POST",
            },
            putRoleDetail: {
                url: "/roledetail",
                version: "/v1",
                requestType: "PUT",
            },
            putRolePrivilege: {
                url: "/roledetail/roleprivilege",
                version: "/v1",
                requestType: "PUT",
            },
            getPrivileges: {
                url: "/privileges/GET",
                version: "/v1",
                requestType: "GET",
            },
        },
        manageMembers: {
            getMemberList: {
                url: "/memberManagement/getMembers",
                version: "/v1",
                requestType: "GET",
            },
            updateGridPreferences: {
                url: "/memberManagement/updateGridPreferences/",
                version: "/v1",
                requestType: "PUT",
            },
            memberExportFile: {
                url: "/memberManagement/exportFile/getMembers",
                version: "/v1",
                requestType: "POST",
            },
            getMemberPersonalDetails: {
                url: "/memberManagement/getMemberPersonalDetails",
                version: "/v1",
                requestType: "GET",
            },
            getOrganizationPersonalDetails: {
                url: "/memberManagement/OrgPersonalDetail",
                version: "/v1",
                requestType: "GET",
            },
            getMemberMasterData: {
                url: "/memberManagement/getMemberMasterData/",
                version: "/v1",
                requestType: "GET",
            },
            getMembershipTypeList: {
                url: "/membershiptype/get",
                version: "/v1",
                requestType: "GET",
            },
            checkIsCompanyAndAddressExists: {
                url: "/memberManagement/checkIsCompanyAndAddressExists",
                version: "/v1",
                requestType: "POST",
            },
            checkIsCompanyAndAddressDetailsExists: {
                url: "/memberManagement/checkIsCompanyAndAddressDetailsExists",
                version: "/v1",
                requestType: "POST",
            },
            getMemberFirstNameList: {
                url: "/memberManagement/getFirstNameList",
                version: "/v1",
                requestType: "GET",
            },
            getMemberLastNameList: {
                url: "/memberManagement/getLastNameList",
                version: "/v1",
                requestType: "GET",
            },
            getMemberOrganizationNameList: {
                url: "/memberManagement/getOrganizationNameList",
                version: "/v1",
                requestType: "GET",
            },
            getMemberCommitteeDesignationNameList: {
                url: "/memberManagement/getCommitteeDesignationNameList",
                version: "/v1",
                requestType: "GET",
            },
            getOrganizationList: {
                url: "/memberManagement/getOrganizations",
                version: "/v1",
                requestType: "GET",
            },
            organizationExportFile: {
                url: "/memberManagement/exportFile/getOrganizations",
                version: "/v1",
                requestType: "GET",
            },
            getOrganizationName: {
                url: "/memberManagement/getOrganizationName",
                version: "/v1",
                requestType: "GET",
            },
            getRepresentativeFirstName: {
                url: "/memberManagement/getRepresentativeFirstName",
                version: "/v1",
                requestType: "GET",
            },
            getRepresentativeLastName: {
                url: "/memberManagement/getRepresentativeLastName",
                version: "/v1",
                requestType: "GET",
            },
            getMOUContactCodeList: {
                url: "/memberManagement/getMOUContactCodeList",
                version: "/v1",
                requestType: "GET",
            },
            getGovernmentTypeList: {
                url: "/memberManagement/getGovernmentTypeList",
                version: "/v1",
                requestType: "GET",
            },
            getInterestedCommitteeList: {
                url: "/memberManagement/getCommitteeOfInterestList",
                version: "/v1",
                requestType: "GET",
            },
            updateMemberDetails: {
                url: "/memberManagement/putMemberDetails",
                version: "/v1",
                requestType: "PUT",
            },
            getMemberCommunicationLogs: {
                url: "/memberManagement/getMemberCommunicationLog",
                version: "/v1",
                requestType: "GET",
            },
            getMemberUserList: {
                url: "/memberManagement/getMemberUserList",
                version: "/v1",
                requestType: "GET",
            },
            saveMemberCommunicationLog: {
                url: "/memberManagement/saveMemberCommunicationLog",
                version: "/v1",
                requestType: "POST",
            },
            getRepHistoryByAccountNumber: {
                url: "/memberManagement/getRepHistoryByAccountNumber",
                version: "/v1",
                requestType: "Get",
            },
            getMembershipTypeInfoByMemberTypeId: {
                url: "/membershiptype/getMembershipTypeInfoByMemberTypeId/",
                version: "/v1",
                requestType: "Get",
            },
            exportMemberWordDocument: {
                url: "/memberManagement/exportMemberWordDocument",
                version: "/v1",
                requestType: "Post",
            },
            updateMembershipTypeInfo: {
                url: "/memberManagement/updateMembershipTypeInfo",
                version: "/v1",
                requestType: "PUT",
            },
            updateMemberCommunicationLog: {
                url: "/memberManagement/moderate/updateMemberCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            updateOwnMemberCommunicationLog: {
                url: "/memberManagement/updateOwnMemberCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            removeMemberCommunicationLog: {
                url: "/memberManagement/moderate/removeMemberCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            removeOwnMemberCommunicationLog: {
                url: "/memberManagement/removeOwnMemberCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            getOrganizationCommunicationLogs: {
                url: "/memberManagement/getOrganizationCommunicationLog",
                version: "/v1",
                requestType: "GET",
            },
            getOrganizationUserList: {
                url: "/memberManagement/getOrganizationUserList",
                version: "/v1",
                requestType: "GET",
            },
            saveOrganizationCommunicationLog: {
                url: "/memberManagement/saveOrganizationCommunicationLog",
                version: "/v1",
                requestType: "POST",
            },
            updateOrganizationCommunicationLog: {
                url: "/memberManagement/moderate/updateOrganizationCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            updateOwnOrganizationCommunicationLog: {
                url: "/memberManagement/updateOwnOrganizationCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            removeOrganizationCommunicationLog: {
                url: "/memberManagement/moderate/removeOrganizationCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            removeOwnOrganizationCommunicationLog: {
                url: "/memberManagement/removeOwnOrganizationCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            getMemberOrderHistory: {
                url: "/memberManagement/getOrderHistorytList",
                version: "/v1",
                requestType: "GET",
            },
            getOrgRepActiveMappingList: {
                url: "/memberManagement/getOrgRepActiveMappingList",
                version: "/v1",
                requestType: "GET",
            },
            getMemberOrgActiveMapping: {
                url: "/memberManagement/getMemberOrgActiveMapping",
                version: "/v1",
                requestType: "GET",
            },
            updateOrgRepMapping: {
                url: "/memberManagement/updateOrgRepMapping",
                version: "/v1",
                requestType: "PUT",
            },
            updateAccountDetails: {
                url: "/memberManagement/updateAccountDetails",
                version: "/v1",
                requestType: "PUT",
            },
            getHistoricalReasonList: {
                url: "/memberManagement/getInactiveReason",
                version: "/v1",
                requestType: "GET",
            },
            updateOrganizationAccountDetails: {
                url: "/memberManagement/UpdateOrgAccountStatusDetails",
                version: "/v1",
                requestType: "PUT",
            },
            updateRepAccountStatus: {
                url: "/memberManagement/updateRepAccountStatus",
                version: "/v1",
                requestType: "PUT",
            },
            getMemberAuditLog: {
                url: "/memberManagement/getMemberAuditLog",
                version: "/v1",
                requestType: "GET",
            },
            getMemberAuditLogFieldList: {
                url: "/memberManagement/getMemberAuditLogFieldList",
                version: "/v1",
                requestType: "GET",
            },
            getMemberCommitteeInfo: {
                url: "/memberManagement/getMemberCommitteeInfo",
                version: "/v1",
                requestType: "GET",
            },
            getMemberCommitteeProfile: {
                url: "/memberManagement/getCommitteeProfile",
                version: "/v1",
                requestType: "GET",
            },
            getMemberMainCommittees: {
                url: "/memberManagement/getMemberMainCommittees",
                version: "/v1",
                requestType: "GET",
            },
            getMemberMainSubCommittee: {
                url: "/memberManagement/getMemberCommitteeList",
                version: "/v1",
                requestType: "GET",
            },
            getMemberCommitteeDetails: {
                url: "/memberManagement/getMemberCommitteeDetails",
                version: "/v1",
                requestType: "GET",
            },
            getMemberOfficerDetail: {
                url: "/officerdetailList",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeAutoSearch: {
                url: "/memberManagement/getCommitteeAutoSearch",
                version: "/v1",
                requestType: "GET",
            },
            getCommonCommitteeClassification: {
                url: "/memberManagement/getCommonCommitteeClassificationType",
                version: "/v1",
                requestType: "GET",
            },
            getCommonOfficerTitle: {
                url: "/memberManagement/getCommonOfficerTitleIds",
                version: "/v1",
                requestType: "GET",
            },
            getNoVoteReason: {
                url: "/memberManagement/getNoVoteReasons",
                version: "/v1",
                requestType: "GET",
            },
            getValidateProducerClassification: {
                url: "/memberManagement/getValidateProducerClassification",
                version: "/v1",
                requestType: "GET",
            },
            getMemberAccountHistoryDetails: {
                url: "/memberManagement/getMemberAccountHistoryDetails/",
                version: "/v1",
                requestType: "GET",
            },
            getOfficerResponsibilities: {
                url: "/memberManagement/getOfficerCommitteeMemberPrivilege",
                version: "/v1",
                requestType: "POST",
            },
            UpdateMemberCommitteeOfficerResponsibilities: {
                url: "/memberManagement/UpdateMemberCommitteeOfficerResponsibilities",
                version: "/v1",
                requestType: "PUT",
            },
            getMemberCommitteeClassification: {
                url: "/memberManagement/getMemberCommitteeClassifications",
                version: "/v1",
                requestType: "GET",
            },
            getImpactedCommitteeList: {
                url: "/memberManagement/getImpactedParentCommittees",
                version: "/v1",
                requestType: "POST",
            },
            putMemberCommittees: {
                url: "/memberManagement/updateMultipleMemberCommittee",
                version: "/v1",
                requestType: "PUT",
            },
            getMemberCommitteeHistoryDetails: {
                url: "/memberManagement/getMemberCommitteeStatusHistoryDetails/",
                version: "/v1",
                requestType: "GET",
            },
            getMemberCommitteeOfficerTitleHistoryDetails: {
                url: "/memberManagement/getMemberCommitteeOfficerTitleStatusHistoryDetails/",
                version: "/v1",
                requestType: "GET",
            },
            getOfficerTitleList: {
                url: "/memberManagement/getCommitteeMemberOfficerTitles",
                version: "/v1",
                requestType: "GET",
            },
            getOfficerTitlesAsPerRulesAndExceptionOrder: {
                url: "/committeeManagement/OfficerTitleSortedList",
                version: "/v1",
                requestType: "POST",
            },
            getInactiveReasonList: {
                url: "/memberManagement/getMemberCommitteeInactiveReasons/",
                version: "/v1",
                requestType: "GET",
            },
            getOrganizationalAuditLogFieldList: {
                url: "/memberManagement/getOrganizationalAuditLogFieldList",
                version: "/v1",
                requestType: "GET",
            },
            getAssociatedCommitteeList: {
                url: "/memberManagement/getAssociatedCommitteeList/",
                version: "/v1",
                requestType: "GET",
            },
            putMemberCommitteeGeneralInfo: {
                url: "/MemberManagement/UpdateCommitteeMemberGeneralInformation",
                version: "/V1",
                requestType: "PUT",
            },
            countryAndStateList: {
                url: "/memberManagement/countryAndStateList",
                version: "/v1",
                requestType: "GET",
            },
            getActiveFeeGroupList: {
                url: "/memberManagement/getActiveFeeGroupList",
                version: "/v1",
                requestType: "GET",
            },
            getDemographicDetails: {
                url: "/memberManagement/getDemographicDetails",
                version: "/v1",
                requestType: "GET",
            },
            getMemberTempInformation: {
                url: "/memberManagement/MemberTempInformation",
                version: "/v1",
                requestType: "GET",
            },
            getCompanyByMemberAccountNumber: {
                url: "/memberManagement/getCompanyByMemberAccountNumber",
                version: "/v1",
                requestType: "GET",
            },
            getCompanyAssociatedMembersDetails: {
                url: "/memberManagement/getCompanyAssociatedMembersDetails",
                version: "/v1",
                requestType: "POST",
            },
            postMasterVolume: {
                url: "/addMember/postMasterVolume",
                version: "/v1",
                requestType: "POST",
            },
            createNewMember: {
                url: "/addMember/CreateMember",
                version: "/v1",
                requestType: "POST",
            },
            checkAssociateMember: {
                url: "/memberManagement/validateActiveRep",
                version: "/v1",
                requestType: "POST",
            },
            updateDemographicInfo: {
                url: "/memberManagement/updateDemographicInfo",
                version: "/v1",
                requestType: "POST",
            },
            updateCompanyInfo: {
                url: "/memberManagement/updateCompanyInfo",
                version: "/v1",
                requestType: "PUT",
            },
            updateOrganization: {
                url: "/memberManagement/updateOrganization",
                version: "/v1",
                requestType: "PUT",
            },
            getMemberAccountDetails: {
                url: "/memberManagement/getMemberAccountDetails",
                version: "/v1",
                requestType: "GET",
            },
            getMemberRNETemplate: {
                url: "/membership/template",
                version: "/v1",
                requestType: "GET",
            },
            getMemberDetail: {
                url: "/memberManagement/MemberDetail/",
                version: "/v1",
                requestType: "GET",
            },
            getMembershipOrderDetails: {
                url: "/addMember/order",
                version: "/v1",
                requestType: "GET",
            },
            getOrganizationAssociatedMember: {
                url: "/memberManagement/associatedMembers",
                version: "/v1",
                requestType: "GET",
            },
            getMemberFeeGroupTabDetail: {
                url: "/memberManagement/FeeGroupTabDetail",
                version: "/v1",
                requestType: "GET",
            },
        },
        manageCommittee: {
            getCommitteeList: {
                url: "/committeeManagement/getCommitteeList",
                version: "/v1",
                requestType: "GET",
            },
            updateGridPreferences: {
                url: "/committeeManagement/updateGridPreferences/",
                version: "/v1",
                requestType: "PUT",
            },
            getUnSuppressedSubCommitteeList: {
                url: "/committeeManagement/getUnSuppressedSubCommitteeList/",
                version: "/v1",
                requestType: "GET",
            },
            updateCommitteeActivityInfo: {
                url: "/committeeManagement/memberParticipation/UpdateCommitteeActivityInfo/",
                version: "/v1",
                requestType: "PUT",
            },
            updateMemberCommittee: {
                url: "/committeeManagement/memberParticipation/updateMemberCommittee",
                version: "/v1",
                requestType: "PUT",
            },
            putMemberCommitteeGeneralInfo: {
                url: "/committeeManagement/memberParticipation/UpdateCommitteeMemberGeneralInformation",
                version: "/v1",
                requestType: "PUT",
            },
            updateCommitteeMemberBasicInformation: {
                url: "/committeeManagement/memberParticipation/UpdateCommitteeMemberBasicInformation/",
                version: "/v1",
                requestType: "PUT",
            },
            memberCommitteeListBulkEdits: {
                url: "/committeeManagement/memberParticipation/getMemberCommitteesSearch",
                version: "/v1",
                requestType: "PUT",
            },
            saveMemberCommittee: {
                url: "/committeeManagement/addMemberToCommittee/saveMemberCommittee",
                version: "/v1",
                requestType: "POST",
            },
            committeeExportFile: {
                url: "/committeeManagement/exportFile/getCommitteeList",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteeTypeList: {
                url: "/committeetype",
                version: "/v1",
                requestType: "GET",
            },
            getAllCommitteeLevel: {
                url: "/committeetype/getAllCommitteeLevel",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteeDesignation: {
                url: "/committeeManagement/getCommitteeDesignation/",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteeTitle: {
                url: "/committeeManagement/getCommitteeTitle/",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteesForSearch: {
                url: "/committeeManagement/getCommitteesForSearch",
                version: "/v1",
                requestType: "GET",
            },
            getAllCommitteeHierarchy: {
                url: "/committeetype/getAllCommitteeHierarchy",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteeOverviewTemplateField: {
                url: "/committeeManagement/getCommitteeOverviewTemplateField",
                version: "/v1",
                requestType: "GET",
            },
            isAlreadyExistsCommitteeDesignation: {
                url: "/committeeManagement/isAlreadyExistsCommitteeDesignation/",
                version: "/v1",
                requestType: "GET",
            },
            addCommittee: {
                url: "/committeeManagement/addCommittee",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeDetails: {
                url: "/committeeManagement/getCommitteeDetails/",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteeMeetingSequence: {
                url: "/committeeManagement/getCommitteeMeetingSequence/",
                version: "/v1",
                requestType: "GET",
            },
            getMeetingTypeList: {
                url: "/meetingtype/getMeetingTypeList",
                version: "/v1",
                requestType: "GET",
            },
            updateCommittee: {
                url: "/committeeManagement/updateCommittee/",
                version: "/v1",
                requestType: "PUT",
            },
            removeCommitteeMeetingSequence: {
                url: "/committeeManagement/removeCommitteeMeetingSequence/",
                version: "/v1",
                requestType: "PUT",
            },
            addCommitteeMeetingSequence: {
                url: "/committeeManagement/addCommitteeMeetingSequence",
                version: "/v1",
                requestType: "POST",
            },
            updateCommitteeMeetingAndAttendees: {
                url: "/committeeManagement/updateCommitteeMeetingAndAttendees/",
                version: "/v1",
                requestType: "PUT",
            },
            getCommitteeCommunicationLogs: {
                url: "/committeeManagement/getCommitteeCommunicationLog",
                version: "/v1",
                requestType: "GET",
            },
            saveCommitteeCommunicationLog: {
                url: "/committeeManagement/saveCommitteeCommunicationLog",
                version: "/v1",
                requestType: "POST",
            },
            updateCommitteeCommunicationLog: {
                url: "/committeeManagement/moderate/updateCommitteeCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            updateOwnCommitteeCommunicationLog: {
                url: "/committeeManagement/updateOwnCommitteeCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            removeCommitteeCommunicationLog: {
                url: "/committeeManagement/moderate/removeCommitteeCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            removeOwnCommitteeCommunicationLog: {
                url: "/committeeManagement/removeOwnCommitteeCommunicationLog",
                version: "/v1",
                requestType: "PUT",
            },
            getCommitteeUserList: {
                url: "/committeeManagement/getCommitteeUserList",
                version: "/v1",
                requestType: "GET",
            },
            addCommitteeMeetingDate: {
                url: "/committeeManagement/addCommitteeMeetingDate",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeMeetingDateList: {
                url: "/committeeManagement/getCommitteeMeetingDateList/",
                version: "/v1",
                requestType: "GET",
            },
            updateCommitteeMeetingDate: {
                url: "/committeeManagement/updateCommitteeMeetingDate/",
                version: "/v1",
                requestType: "PUT",
            },
            searchCommitteeRosterMember: {
                url: "/memberManagement/searchCommitteeRosterMember/",
                version: "/v1",
                requestType: "GET",
            },
            checkMemberExistsInParentCommittee: {
                url: "/memberManagement/memberExistsInParentCommittee/",
                version: "/v1",
                requestType: "GET",
            },
            SaveRosterCommitteeMemberInfo: {
                url: "/committeeManagement/addMemberToCommittee/SaveRosterCommitteeMemberInfo",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeRoster: {
                url: "/committeeManagement/getCommitteeRoster",
                version: "/v1",
                requestType: "POST",
            },
            getExportCommitteeRoster: {
                url: "/committeeManagement/getCommitteeRoster/export",
                version: "/v1",
                requestType: "POST",
            },
            getClassificationTypeList: {
                url: "/committeeManagement/getClassificationTypeList/",
                version: "/v1",
                requestType: "GET",
            },
            postCommitteeRoster: {
                url: "/committeeManagement/memberParticipation/updateCommitteeRoster",
                version: "/v1",
                requestType: "PUT",
            },
            updateCommitteeClassification: {
                url: "/committeeManagement/updateCommitteeClassification/",
                version: "/v1",
                requestType: "PUT",
            },
            updateCommitteeSettings: {
                url: "/committeeManagement/updateCommitteeSettings/",
                version: "/v1",
                requestType: "PUT",
            },
            deleteCommittee: {
                url: "/committeeManagement/deleteCommittee/",
                version: "/v1",
                requestType: "DELETE",
            },
            getStatisticalCommitteeInfo: {
                url: "/committeeManagement/getStatisticalCommitteeInfo/",
                version: "/v1",
                requestType: "GET",
            },
            getOperatorList: {
                url: "/committeetype/operator",
                version: "/v1",
                requestType: "GET",
            },
            getPrimaryActivities: {
                url: "/committeeManagement/getPrimaryActivities/",
                version: "/v1",
                requestType: "GET",
            },
            getOfficerTitleOnCommitteeLevel: {
                url: "/committeeManagement/getOfficerTitleOnCommitteeLevel/",
                version: "/v1",
                requestType: "GET",
            },
            getMemberOnCommittee: {
                url: "/committeeManagement/getMemberOnCommittee/",
                version: "/v1",
                requestType: "GET",
            },
            postCommitteeOfficer: {
                url: "/committeeManagement/bulkUpdateOfficerTitle",
                version: "/v1",
                requestType: "POST",
            },
            getApplicationPrivilege: {
                url: "/committeetype/applicationprivilege",
                version: "/v1",
                requestType: "GET",
            },
            getApplicationPrivilegeOnCommitteeType: {
                url: "/committeetype/getApplicationPrivilegeOnCommitteeType/",
                version: "/v1",
                requestType: "GET",
            },
            updateEnableCommitteeOnWeb: {
                url: "/committeeManagement/updateEnableCommitteeOnWeb/",
                version: "/v1",
                requestType: "PUT",
            },
            getOfficerDetail: {
                url: "/officertitleDetail/",
                version: "/v1",
                requestType: "GET",
            },
            getOfficerResponsibilityCommittee: {
                url: "/committeeManagement/getOfficerResponsibilityCommittee",
                version: "/v1",
                requestType: "POST",
            },
            manageCommitteeOfficerTitleExtendedResponsibility: {
                url: "/committeeManagement/manageCommitteeOfficerTitleExtendedResponsibility",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeAuditLogFieldList: {
                url: "/committeeManagement/getCommitteeAuditLogFieldList",
                version: "/v1",
                requestType: "GET",
            },
            getCommitteeAuditLog: {
                url: "/committeeManagement/getCommitteeAuditLog/",
                version: "/v1",
                requestType: "GET",
            },
            getBylawsRevisionHistoryList: {
                url: "/committeeManagement/getBylawsRevisionHistoryList/",
                version: "/v1",
                requestType: "GET",
            },
            getSearchActiveCommittees: {
                url: "/committeeManagement/searchActiveCommittees",
                version: "/v1",
                requestType: "GET",
            },
            getCountActiveMembersInCommittee: {
                url: "/committeeManagement/CountActiveMembersInCommittee",
                version: "/v1",
                requestType: "GET",
            },
            postCopyMembersToCommittee: {
                url: "/committeeManagement/addMemberToCommittee/copyMembersToCommittee",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeInactiveReason: {
                url: "/committeeManagement/inactiveReasons",
                version: "/v1",
                requestType: "GET",
            },
            getDependentCommittees: {
                url: "/committeeManagement/getDependentCommittees",
                version: "/v1",
                requestType: "GET",
            },
            putInactivateCommittee: {
                url: "/committeeManagement/InactivateCommittee",
                version: "/v1",
                requestType: "PUT",
            },
            getMergeActiveCommittees: {
                url: "/committeeManagement/mergeActiveCommittees",
                version: "/v1",
                requestType: "GET",
            },
            putReactivateCommittee: {
                url: "/committeeManagement/ReactivateCommittee",
                version: "/v1",
                requestType: "PUT",
            },
            getBosVolumeForCommittee: {
                url: "/committeeManagement/getBosVolumeForCommittee",
                version: "/v1",
                requestType: "GET",
            },
            getMemberAccountNumbers: {
                url: "/committeeManagement/getMemberAccountNumbers",
                version: "/v1",
                requestType: "POST",
            },
        },
        feeGroup: {
            addFeeGroup: {
                url: "/feeGroup",
                version: "/v1",
                requestType: "POST",
            },
            updateFeeGroup: {
                url: "/feeGroup",
                version: "/v1",
                requestType: "PUT",
            },
            getFeeGrouplist: {
                url: "/feeGroup",
                version: "/v1",
                requestType: "GET",
            },
            checkDuplicateFeeGroupTitle: {
                url: "/feeGroup/uniqCheck",
                version: "/v1",
                requestType: "GET",
            },
            getFeeGroupBannerDetail: {
                url: "/feeGroup/FeeGroupBannerDetail",
                version: "/v1",
                requestType: "GET",
            },
            getFeeGroupTabDetail: {
                url: "/feeGroup/FeeGroupTabDetail",
                version: "/v1",
                requestType: "GET",
            },
            updateFeeGroupStatus: {
                url: "/feeGroup/changeFeeGroupStatus",
                version: "/v1",
                requestType: "PUT",
            },
            getFeeGroupDeAssociatedMembers: {
                url: "/feeGroup/FeeGroupMembers",
                version: "/v1",
                requestType: "PUT",
            },
            getFeeGroupAssociatedMemberList: {
                url: "/feeGroup/FeeGroupAssociatedMembers",
                version: "/v1",
                requestType: "GET",
            },
            associateMemberFeeGroup: {
                url: "/feeGroup/associateFeeGroupMembers",
                version: "/v1",
                requestType: "PUT",
            },
            deleteAssociatedMember: {
                url: "/feeGroup/removeMembersFromFeeGroup",
                version: "/v1",
                requestType: "PUT",
            },
            getFeeGroupInvoiceDetails: {
                url: "/feeGroup/FeeGroupInvoiceDetails",
                version: "/v1",
                requestType: "GET",
            },
        },
        workItemAdmin: {
            workItemDetails: {
                url: "/workItemAdmin/workItemDetail/",
                version: "/v1",
                requestType: "GET",
            },
            standardTypeList: {
                url: "/workItemAdmin/standardTypes",
                version: "/v1",
                requestType: "GET",
            },
            updateWorkItemDetails: {
                url: "/workItemAdmin",
                version: "/v1",
                requestType: "PUT",
            },
            deleteWorkItem: {
                url: "/workItemAdmin/Delete",
                version: "/v1",
                requestType: "PUT",
            },
            restoreWorkItem: {
                url: "/workItemAdmin/recoverWorkItem/",
                version: "/v1",
                requestType: "PUT",
            },
            deletedWorkItemList: {
                url: "/workItemAdmin/viewDeletedWorkItem",
                version: "/v1",
                requestType: "POST",
            },
            workItemAuditLogs: {
                url: "/workItemAdmin/workItemAuditLogs",
                version: "/v1",
                requestType: "GET",
            },
            getSubCommitteesList: {
                url: "/workItemAdmin/getSubCommitteeList/",
                version: "/v1",
                requestType: "GET",
            },
            getMemberList: {
                url: "/workItemAdmin/getMemberList/",
                version: "/v1",
                requestType: "GET",
            },
            getBallotItemSubmissionList: {
                url: "/workItemAdmin/getBallotItemSubmissionList",
                version: "/v1",
                requestType: "GET",
            },
            getPreReleaseTargetDateList: {
                url: "/workItemAdmin/getPreReleaseTarget/",
                version: "/v1",
                requestType: "PUT",
            },
            auditLog: {
                url: "/workItemAdmin/workItemAuditLog",
                version: "/v1",
                requestType: "POST",
            },
            sendCollabApiFailureEmail: {
                url: "/workItemAdmin/sendCollabApiFailureEmail",
                version: "/v1",
                requestType: "POST",
            },
        },
        membershipRenewal: {
            getStartRenewalOnWeb: {
                url: "/memberShipRenewal/getStartRenewal",
                version: "/v1",
                requestType: "GET",
            },
            getFeeDropReportList: {
                url: "/memberShipRenewal/getFeeDropReportList",
                version: "/v1",
                requestType: "GET",
            },
            getAutoRenewalMemberList: {
                url: "/memberShipRenewal/getAutoRenewalMemberList",
                version: "/v1",
                requestType: "GET",
            },
            getMAILAndASTMReports: {
                url: "/memberShipRenewal/astmAndMailReport",
                version: "/v1",
                requestType: "GET",
            },
            updateRenewalYear: {
                url: "/memberShipRenewal/startRenewalOnWeb",
                version: "/v1",
                requestType: "PUT",
            },
            processRenewalScheduler: {
                url: "/memberShipRenewal/startRenewalOnWeb/getProcessRenewalScheduler",
                version: "/v1",
                requestType: "POST",
            },
            processFeeDrop: {
                url: "/memberShipRenewal/processFeeDrop/put",
                version: "/v1",
                requestType: "PUT",
            },
            processTemporaryMemberDrop: {
                url: "/memberShipRenewal/processTemporaryMemberDrop/put",
                version: "/v1",
                requestType: "PUT",
            },
            getAwsSigned: {
                url: "/memberShipRenewal/getAwsSigned",
                version: "/v1",
                requestType: "GET",
            },
            sendSystemErrorMail: {
                url: "/memberShipRenewal/sendSystemMail",
                version: "/v1",
                requestType: "GET",
            },
            bookVolumeInfo: {
                url: "/memberShipRenewal/bookVolumeInfo",
                version: "/v1",
                requestType: "PUT",
            },
        },
        ballotAdmin: {
            getMemberPersonalDetails: {
                url: "/ballotAdmin/getMemberPersonalDetails",
                version: "/v1",
                requestType: "GET",
            },
            getBallotListAdmin: {
                url: "/ballotAdmin/BallotSearch",
                version: "/v1",
                requestType: "GET",
            },
            getBallotItemByLetterBallot: {
                url: "/ballotAdmin/ballotitems",
                version: "/v1",
                requestType: "GET",
            },
            getBallotDetailsByLetterBallot: {
                url: "/ballotAdmin/BallotDetails",
                version: "/v1",
                requestType: "GET",
            },
            updateDescription: {
                url: "/ballotAdmin/BallotItem/description",
                version: "/v1",
                requestType: "POST",
            },
            getFileDescription: {
                url: "ballotAdmin/BallotItem/description",
                version: "/v1",
                requestType: "POST",
            },
            getBallotVoteHistory: {
                url: "/ballotAdmin/ballot/vote",
                version: "/v1",
                requestType: "GET",
            },
        },
        manageManualOrder: {
            searchCompany: {
                url: "/addMember/searchCompany/",
                version: "/v1",
                requestType: "GET",
            },
            companyAddressList: {
                url: "/addMember/companyAddress/",
                version: "/v1",
                requestType: "GET",
            },
            addressValidation: {
                url: "/addMember/checkAddressOnValidationAPI",
                version: "/v1",
                requestType: "POST",
            },
        },
        meetingReport: {
            getEvemtList: {
                url: "/reports/meeting-reports/event",
                version: "/v1",
                requestType: "GET",
            },
            getEventCommitteeList: {
                url: "/reports/meeting-reports/eventMainCommitteeList",
                version: "/v1",
                requestType: "GET",
            },
            getLineScheduleReportData: {
                url: "/reports/meeting-reports/line-schedule-report",
                version: "/v1",
                requestType: "GET",
            },
            getCommiteeAttendenceData: {
                url: "/reports/meeting-reports/registration-fees-report/attendanceSummary",
                version: "/v1",
                requestType: "GET",
            },
            getAttendeeList: {
                url: "/reports/meeting-reports/registration-fees-report/attendee",
                version: "/v1",
                requestType: "POST",
            },
            getAttendeeFeeList: {
                url: "/reports/meeting-reports/registration-fees-report/attendeeWithFee",
                version: "/v1",
                requestType: "POST",
            },
            getBlockScheduleReport: {
                url: "/reports/meeting-reports/block-schedule-report",
                version: "/v1",
                requestType: "GET",
            },
            getAccompanyingCompanionReport: {
                url: "/reports/meeting-reports/accompanyingcompanionreport",
                version: "/v1",
                requestType: "GET",
            },
            getDoorSignReport: {
                url: "/reports/meeting-reports/doorsignreport",
                version: "/v1",
                requestType: "GET",
            },
            getCostCenterReport: {
                url: "/reports/meeting-reports/costCenterReport",
                version: "/v1",
                requestType: "GET",
            },
            getAccountingReport: {
                url: "/reports/meeting-reports/accountingReport",
                version: "/v1",
                requestType: "GET",
            },
            getChairReceptionListReport: {
                url: "/reports/meeting-reports/chairReceptionListReport",
                version: "/v1",
                requestType: "GET",
            },
        },
        membershipException: {
            getMembershipExceptionList: {
                url: "/membership-exception/exception-list",
                version: "/v1",
                requestType: "GET",
            },
            updateGridPreferences: {
                url: "/membership-exception/updateGridPreferences/",
                version: "/v1",
                requestType: "PUT",
            },
            updateExceptionStatus: {
                url: "/membership-exception/updateExceptionStatus",
                version: "/v1",
                requestType: "PUT",
            },
        },
        common: {
            getSignedUrlforDownload: {
                url: "/getSignedUrl",
                version: "/v1",
                requestType: "POST",
            },
            getBOSVolumeMasterList: {
                url: "/master/bosVolumeList",
                version: "/v1",
                requestType: "GET",
            },
            downloadS3File: {
                url: "/document/download",
                version: "/v1",
                requestType: "GET",
            },
            verifyDocExists: {
                url: "/document/download/verify",
                version: "/v1",
                requestType: "GET",
            },
        },
        reports: {
            volumeMembers: {
                url: "/reports/membership-reports/change-book-volume",
                version: "/v1",
                requestType: "GET",
            },
            getUnpaidOfficers: {
                url: "/reports/renewal-reports/unpaid-officer",
                version: "/v1",
                requestType: "GET",
            },
            mcsStatusReport: {
                url: "/reports/membership-reports/statusReport?",
                version: "/v1",
                requestType: "GET",
            },
            orgAndNonOrgReport: {
                url: "/reports/email-reminder-list",
                version: "/v1",
                requestType: "GET",
            },
            membershipRevenuesReport: {
                url: "/reports/membership-reports/membership-Revenues-report?",
                version: "/v1",
                requestType: "GET",
            },
            societyMemberReport: {
                url: "/reports/membership-reports/society-report?",
                version: "/v1",
                requestType: "GET",
            },
            monthlyStatisticsReport: {
                url: "/reports/membership-reports/monthly-statistics-report?",
                version: "/v1",
                requestType: "GET",
            },
        },
        bosVolumeLocation: {
            getBOSVolumeLocation: {
                url: "/bosVolume/getList",
                version: "/v1",
                requestType: "GET",
            },
            updateeBOSVolumeLocation: {
                url: "/bosVolume/",
                version: "/v1",
                requestType: "PUT",
            },
        },
        openAPI: {
            flagFeature: {
                url: "/master/featureflag",
                version: "/v1",
                requestType: "GET",
            },
        },
        documents: {
            documentCategory: {
                url: "/documentCategoryList",
                version: "/v1",
                requestType: "GET",
            },
            getActiveCommittees: {
                url: "/committeemanagement/activeCommittees",
                version: "/v1",
                requestType: "GET",
            },
            postAgendasDocument: {
                url: "/documents/agenda/uploadDocument",
                version: "/v1",
                requestType: "POST",
            },
            postMinutesDocument: {
                url: "/documents/minutes/uploadDocument",
                version: "/v1",
                requestType: "POST",
            },
            postCheckDocument: {
                url: "/documents/checkDocument",
                version: "/v1",
                requestType: "POST",
            },
            getAgendasList: {
                url: "/document/agendas",
                version: "/v1",
                requestType: "POST",
            },
            getMinutesList: {
                url: "/document/minutes",
                version: "/v1",
                requestType: "POST",
            },
            getCommitteeDocumentsList: {
                url: "/document/committeeDocuments",
                version: "/v1",
                requestType: "POST",
            },
            getDocumentList: {
                url: "/getDocumentList",
                version: "/v1",
                requestType: "POST",
            },
            getDocumentInfoById: {
                url: "/getDocumentInfoById",
                version: "/v1",
                requestType: "POST",
            },
            agendasDocDelete: {
                url: "/document/agendas",
                version: "/v1",
                requestType: "DELETE",
            },
            minutesDocDelete: {
                url: "/document/minutes",
                version: "/v1",
                requestType: "DELETE",
            },
            committeeDocDelete: {
                url: "/document/committeeDocuments",
                version: "/v1",
                requestType: "DELETE",
            },
            getUploadToken: {
                url: "/documents/getUploadToken",
                version: "/v1",
                requestType: "POST",
            },
            postKafkaMessage: {
                url: "/documents/kafkaMessage",
                version: "/v1",
                requestType: "POST",
            },
            updateDocumentInfo: {
                url: "/documents/updateDocumentInfo",
                version: "/v1",
                requestType: "POST",
            },
            postCommitteeDocumentsUpload: {
                url: "/documents/committeeDocuments/uploadDocument",
                version: "/v1",
                requestType: "PUT",
            },
            postCheckCommitteeDocument: {
                url: "/documents/checkCommitteeDocument",
                version: "/v1",
                requestType: "POST",
            },
            replaceDocument: {
                url: "/documents/replaceDocument",
                version: "/v1",
                requestType: "PUT",
            },
            downloadDocumentFileUrl: {
                url: "/document/getDocumentURL",
                version: "/v1",
                requestType: "POST",
            },
            ballotListUrl: {
                url: "/ballotList",
                version: "/v1",
                requestType: "Get",
            },
            ballotItemsUrl: {
                url: "/ballotItems/",
                version: "/v1",
                requestType: "Get",
            },
            checkMemberAccountUrl: {
                url: "/member/checkMemberAccount/",
                version: "/v1",
                requestType: "Get",
            },
            postVoteAttachmentUpload: {
                url: "/document/voteAttachment",
                version: "/v1",
                requestType: "PUT",
            },
            getClosingReportList: {
                url: "/document/closingReport",
                version: "/v1",
                requestType: "POST",
            },
            getVoteAttachmentList: {
                url: "/document/voteAttachment",
                version: "/v1",
                requestType: "POST",
            },
            getDocumentStatusByCategory: {
                url: "/document/getDocumentStatusByCategory",
                version: "/v1",
                requestType: "GET",
            },
            voteAttachmentDocDelete: {
                url: "/document/voteAttachment",
                version: "/v1",
                requestType: "DELETE",
            },
            getBallotQueueList: {
                url: "/document/ballotDocuments",
                version: "/v1",
                requestType: "POST",
            },
            getBallotQueueActions: {
                url: "/documents/ballotQueue/actions",
                version: "/v1",
                requestType: "GET",
            },
            postBallotDocumentsUpload: {
                url: "/document/ballotDocuments",
                version: "/v1",
                requestType: "PUT",
            },
            ballotQueueDocDelete: {
                url: "/document/ballotDocuments",
                version: "/v1",
                requestType: "DELETE",
            },
            ballotFolderStatusList: {
                url: "/document/ballotFolderStatus",
                version: "/v1",
                requestType: "GET",
            },
            ballotFolderDetailList: {
                url: "/document/ballotFolderDetailList",
                version: "/v1",
                requestType: "POST",
            },
            ballotItemResequence: {
                url: "/document/ballotItemSequence",
                version: "/v1",
                requestType: "PUT",
            },
            committeeDocResequence: {
                url: "/document/committeeDocuments",
                version: "/v1",
                requestType: "PUT",
            },
            postBallotItemCheckTermDocExists: {
                url: "/document/ballotItem/checkTermDocExists",
                version: "/v1",
                requestType: "POST",
            },
            postBallotItemCheckItemNumberExists: {
                url: "/document/ballotItem/checkItemNumberExists",
                version: "/v1",
                requestType: "POST",
            },
            postBallotItemCheckBallotStatus: {
                url: "/document/ballotItem/checkBallotStatus",
                version: "/v1",
                requestType: "POST",
            },
            publishBallotFolder: {
                url: "/document/ballotItemPublish",
                version: "/v1",
                requestType: "GET",
            },
            getCheckBallotPublishReady: {
                url: "/document/checkBallotPublishReady",
                version: "/v1",
                requestType: "GET",
            },
            getLetterBallotList: {
                url: "/document/getLetterBallotList",
                version: "/v1",
                requestType: "GET",
            },
            markBallotReadyToPublish: {
                url: "/document/markBallotReadyToPublish",
                version: "/v1",
                requestType: "Put",
            },
            getBallotStatusInformation: {
                url: "/document/ballotStatusInformation",
                version: "/v1",
                requestType: "Get",
            },
            getBallotFolderZipFileStatus: {
                url: "/document/ballotZipFilestatus",
                version: "/v1",
                requestType: "Get",
            },
            postBallotFolderUploadBallotItemZipFile: {
                url: "/document/ballotFolder/uploadBallotItemZipFile",
                version: "/v1",
                requestType: "Post",
            },
            postLitigationHoldApplyLitigation: {
                url: "/manageLitigation",
                version: "/v1",
                requestType: "put",
            },
            getRestrictDocumentDeletion: {
                url: "/document/restrictDocumentDeletion",
                version: "/v1",
                requestType: "Get",
            },
            collaborationAreaDraftList: {
                url: "/manageCollabAreaDraft",
                version: "/v1",
                requestType: "POST",
            },
            deleteBulkBallotQueue: {
                url: "/document/deleteBulkBallotQueue",
                version: "/v1",
                requestType: "DELETE",
            },
            checkMimeType: {
                url: "/document/validateContentType",
                version: "/v1",
                requestType: "Post"
            }
        },
        litigationHold: {
            postSearchLitigations: {
                url: "/manageLitigation",
                version: "/v1",
                requestType: "POST",
            },
            deleteLitigationHold: {
                url: "/manageLitigation",
                version: "/v1",
                requestType: "delete",
            }
        },
    },
    local: {
        login: {
            login: {
                url: "/login",
                version: "/v1",
                requestType: "GET",
            },
        },
    },
};
function getApiUrl(key, name, isOpenAPICall) {
    if (isOpenAPICall === void 0) { isOpenAPICall = false; }
    var url;
    var baseURL = isOpenAPICall === true ? apiUrls.openApiUrl : apiUrls.baseUrl;
    if (baseURL !== null) {
        if (apiUrls.live[key] && apiUrls.live[key][name]) {
            url = baseURL + apiUrls.live[key][name]["version"] + apiUrls.live[key][name]["url"];
        }
        else {
            if (apiUrls.local[key] && apiUrls.local[key][name]) {
                var localURL = isOpenAPICall === true ? apiUrls.localOpenApiUrl : apiUrls.openApiUrl;
                url = localURL + apiUrls.local[key][name]["version"] + apiUrls.local[key][name]["url"];
            }
        }
    }
    else {
        if (apiUrls.local[key] && apiUrls.local[key][name]) {
            var localURL = isOpenAPICall === true ? apiUrls.localOpenApiUrl : apiUrls.openApiUrl;
            url = localURL + apiUrls.local[key][name]["version"] + apiUrls.local[key][name]["url"];
        }
    }
    return url;
}
export default getApiUrl;
