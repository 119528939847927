var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as type from '../../action-type';
var initialState = {
    isDocumentInfo: false,
    allDocumentsClickedId: '',
    activeDocumentInfoDataId: 0,
    displayFileName: '',
    hasUpdateAccess: false
};
var allDocumentsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case type.CLICK_DOCUMENT_INFO_ICON:
            return __assign(__assign({}, state), { isDocumentInfo: action.data.isDocumentInfo, allDocumentsClickedId: action.data.documentId, displayFileName: action.data.displayFileName, hasUpdateAccess: action.data.hasAccess, currentDocumentChangedStatus: action.data.currentDocumentChangedStatus });
        case type.UPDATE_DOCUMENT_LIST:
            return __assign(__assign({}, state), { activeDocumentInfoDataId: action.activeDocumentInfoDataId });
        default:
            return state;
    }
};
export default allDocumentsReducer;
